import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DeleteAction } from '@src/components';
import { useDeleteBrand } from '@src/hooks';
import * as routes from '@src/routes';

export const BrandDeleteAction = ({ brandId, brandName }: { brandId: string; brandName: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, mutateAsync: deleteBrand } = useDeleteBrand();

  const doDelete = () => {
    deleteBrand(brandId).then(() => navigate(generatePath(routes.BRANDS)));
  };

  return (
    <DeleteAction
      loading={isLoading}
      title={t('components.brand.BrandDeleteAction.title')}
      text={t('components.brand.BrandDeleteAction.text')}
      actionLabel={t('components.brand.BrandDeleteAction.action')}
      confirmMatch={brandName}
      onConfirmedDelete={doDelete}
    />
  );
};
