import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnyJobError, BackendJobErrorCode, ScriptErrorCode, WorkerErrorCode } from '@plainly/types';
import { CodeModal, Log, ParamsList, SingleParam } from '@src/components';
import { isEmpty } from '@src/utils';

import { breakLine } from '../utils';

export const WorkerErrorParams = ({ error }: { error: AnyJobError }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const getParamsComponent = () => {
    switch (error.code) {
      // Render and analysis worker errors
      case WorkerErrorCode.AfterEffectsError:
      case WorkerErrorCode.FfmpegEncodingError: {
        return (
          <Log
            title={t('components.common.error.workerError.logs.Log.title', { context: error.code })}
            actionText={t('components.common.error.workerError.logs.Log.showFullLog', { context: error.code })}
            linkAction={() => setShowModal(true)}
          >
            <CodeModal
              content={breakLine(error.params.log)}
              showModal={showModal}
              onClose={() => setShowModal(false)}
              size="md"
              wide
              showCopyAction
            />
          </Log>
        );
      }
      case WorkerErrorCode.DuplicatedFontsFound:
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'duplicatedFonts' })}
            items={error.params.fonts}
          />
        );
      case WorkerErrorCode.PackagedFontsParsingFailed:
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'badFonts' })}
            items={error.params.fonts}
          />
        );
      case WorkerErrorCode.FontsMismatch:
        return (
          <>
            {!isEmpty(error.params.missingFonts) && (
              <ParamsList
                title={t('components.common.error.workerError.list.ParamsList.title', { context: 'missingFonts' })}
                items={error.params.missingFonts}
              />
            )}
            {!isEmpty(error.params.extraFonts) && (
              <ParamsList
                title={t('components.common.error.workerError.list.ParamsList.title', { context: 'extraFonts' })}
                items={error.params.extraFonts}
              />
            )}
          </>
        );
      case WorkerErrorCode.DownloadAssetFailed:
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'assetUrls' })}
            items={error.params.urls}
            wide
          />
        );
      case WorkerErrorCode.ImportAssetsFailed:
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'assetUrls' })}
            items={error.params.assets.map(asset => ({ title: asset.src, description: asset.error }))}
            wide
          />
        );
      case ScriptErrorCode.ProjectMissingFiles:
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'missingFiles' })}
            items={error.params.missingFiles}
            wide
          />
        );
      case WorkerErrorCode.UnsupportedAeVersion: {
        const aeVersions = ['After Effects 2022', 'After Effects 2023', 'After Effects 2024'];
        return (
          <ParamsList
            title={t('components.common.error.workerError.list.ParamsList.title', { context: 'aeVersions' })}
            items={aeVersions}
          />
        );
      }

      // Render script worker errors
      case ScriptErrorCode.ActionWrongLayerType:
        return (
          <>
            <SingleParam
              title={t('components.common.error.workerError.single.SingleParam.title', { context: 'actionName' })}
              item={error.params.actionName}
            />
            <SingleParam
              title={t('components.common.error.workerError.single.SingleParam.title', { context: 'layerName' })}
              item={error.params.layerName}
            />
            <ParamsList
              title={t('components.common.error.workerError.single.SingleParam.title', {
                context: 'correctLayerTypes'
              })}
              items={error.params.correctLayerTypes}
              wide
            />
          </>
        );
      case ScriptErrorCode.RootCompNotAllowed:
        return (
          <SingleParam
            title={t('components.common.error.workerError.single.SingleParam.title', { context: 'actionName' })}
            item={error.params.actionName}
          />
        );
      case ScriptErrorCode.LayerNotFound:
        return (
          <>
            <SingleParam
              title={t('components.common.error.workerError.single.SingleParam.title', { context: 'layerName' })}
              item={error.params.layerName}
            />
            <SingleParam
              title={t('components.common.error.workerError.single.SingleParam.title', { context: 'parentComp' })}
              item={error.params.parentComp}
            />
          </>
        );
      case ScriptErrorCode.CompositionNotFound:
        return (
          <SingleParam
            title={t('components.common.error.workerError.single.SingleParam.title', { context: 'compositionName' })}
            item={error.params.compositionName}
          />
        );

      // backend jobs errors
      case BackendJobErrorCode.InfrastructureFailure:
        return (
          <SingleParam
            title={t('components.common.error.workerError.single.SingleParam.title', { context: 'reason' })}
            item={error.message}
            wide
          />
        );
      default:
        return null;
    }
  };

  return getParamsComponent();
};
