import { ExoticComponent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  ArrowsPointingOutIcon,
  ClockIcon,
  ExclamationCircleIcon,
  FilmIcon,
  MagnifyingGlassIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { AeItemType, ScriptType } from '@plainly/types';
import {
  CropScriptingForm,
  EditableAeItemWithCompositions,
  ExtendScriptingForm,
  ImageSequenceLoaderForm,
  LayerManagementForm,
  MediaAutoScaleScriptingForm,
  SceneManagementForm,
  SetDurationScriptingForm,
  StretchScriptingForm,
  TimeActionScriptingForm
} from '@src/components';
import { Button, Modal } from '@src/components/common';
import { CompositionIdentifier, Script } from '@src/models';

const getApplicableScriptTypes = (item: EditableAeItemWithCompositions, isRootLayer?: boolean): ScriptType[] => {
  if (item.layer.type === AeItemType.COMPOSITION) {
    if (isRootLayer) {
      return [ScriptType.SCENE_MANAGEMENT, ScriptType.SET_DURATION, ScriptType.SPREAD_LAYERS];
    } else {
      return [
        ScriptType.CROP,
        ScriptType.EXTEND,
        ScriptType.LAYER_MANAGEMENT,
        ScriptType.SCENE_MANAGEMENT,
        ScriptType.SET_DURATION,
        ScriptType.SHIFT_IN,
        ScriptType.SHIFT_OUT,
        ScriptType.SPREAD_LAYERS,
        ScriptType.STRETCH,
        ScriptType.TRIM_IN,
        ScriptType.TRIM_OUT
      ];
    }
  } else {
    let scripts: ScriptType[] = [
      ScriptType.CROP,
      ScriptType.EXTEND,
      ScriptType.IMAGE_SEQUENCE_LOADER,
      ScriptType.LAYER_MANAGEMENT,
      ScriptType.SET_DURATION,
      ScriptType.SHIFT_IN,
      ScriptType.SHIFT_OUT,
      ScriptType.STRETCH,
      ScriptType.TRIM_IN,
      ScriptType.TRIM_OUT
    ];

    if (item.layer.type === AeItemType.MEDIA) {
      scripts = [...scripts, ScriptType.MEDIA_AUTO_SCALE];
    }

    if (item.layer.type === AeItemType.TEXT) {
      scripts = [...scripts, ScriptType.TEXT_AUTO_SCALE];
    }

    return scripts;
  }
};

export const getScriptIcon = (scriptType: ScriptType, attributes?: React.ComponentProps<ExoticComponent>) => {
  switch (scriptType) {
    case ScriptType.IMAGE_SEQUENCE_LOADER:
      return <FilmIcon {...attributes} />;

    case ScriptType.LAYER_MANAGEMENT:
    case ScriptType.SCENE_MANAGEMENT:
      return <WrenchScrewdriverIcon {...attributes} />;

    case ScriptType.MEDIA_AUTO_SCALE:
    case ScriptType.TEXT_AUTO_SCALE:
      return <ArrowsPointingOutIcon {...attributes} />;

    default:
      return <ClockIcon {...attributes} />;
  }
};

export type LayerParametrizeScriptingModalProps = {
  show: boolean;
  closeModal: () => void;
  selectedScripts: Script[];
  setSelectedScripts: (scripts: Script[]) => void;
  editScript?: Script;
  compositions: CompositionIdentifier[] | undefined;
  item: EditableAeItemWithCompositions;
};

const LayerParametrizeScriptingModalContent = ({
  closeModal,
  selectedScripts,
  setSelectedScripts,
  editScript,
  compositions,
  item
}: Omit<LayerParametrizeScriptingModalProps, 'show'>) => {
  const { t } = useTranslation();

  const scriptTypes = getApplicableScriptTypes(item, compositions?.length === 0);

  const [query, setQuery] = useState<string>('');
  const [selectedScriptType, setSelectedScriptType] = useState<string | null>(editScript?.scriptType || null);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [formScript, setFormScript] = useState<Script | undefined>();

  const onScriptSelect = useCallback(
    (scriptType: ScriptType) => {
      if (scriptType === ScriptType.SPREAD_LAYERS) {
        setSelectedScripts([
          ...selectedScripts,
          {
            scriptType: ScriptType.SPREAD_LAYERS
          }
        ]);
        closeModal();
      } else if (scriptType === ScriptType.TEXT_AUTO_SCALE) {
        setSelectedScripts([
          ...selectedScripts,
          {
            scriptType: ScriptType.TEXT_AUTO_SCALE
          }
        ]);
        closeModal();
      } else {
        setSelectedScriptType(scriptType);
      }
    },
    [closeModal, selectedScripts, setSelectedScripts]
  );

  const onSave = useCallback(() => {
    formScript && setSelectedScripts([...selectedScripts, formScript]);
    closeModal();
  }, [closeModal, formScript, selectedScripts, setSelectedScripts]);

  const onEdit = useCallback(() => {
    if (editScript) {
      const index = selectedScripts.indexOf(editScript);
      const oldState = [...selectedScripts];
      formScript && oldState.splice(index, 1, formScript);
      setSelectedScripts(oldState);
      closeModal();
    }
  }, [closeModal, editScript, formScript, selectedScripts, setSelectedScripts]);

  const filteredScriptTypes =
    query === ''
      ? scriptTypes.sort()
      : scriptTypes
          .filter(script => {
            return t('components.project.template.layer.LayerParametrizeScriptingModal.name', { context: script })
              .toLowerCase()
              .includes(query.toLowerCase());
          })
          .sort();

  return (
    <>
      {!selectedScriptType && (
        <div className="relative">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            type="text"
            onChange={e => setQuery(e.target.value)}
            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            placeholder={t('general.common.searchPlaceholder')}
            role="combobox"
            aria-expanded="false"
            aria-controls="options"
          />
        </div>
      )}
      {!selectedScriptType && filteredScriptTypes.length > 0 && (
        <ul className="max-h-96 min-h-[200px] scroll-py-3 overflow-y-auto p-3" id="options" role="listbox">
          {filteredScriptTypes.map((scriptType, index) => {
            return (
              <li
                className="group flex cursor-pointer select-none rounded-xl p-3 hover:bg-gray-100"
                key={index}
                id={`option-${index}`}
                role="option"
                tabIndex={-1}
                aria-selected={selectedScriptType === scriptType}
                onClick={() => onScriptSelect(scriptType)}
              >
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
                  {getScriptIcon(scriptType, { className: 'h-6 w-6 text-white' })}
                </div>
                <div className="ml-4 flex-auto">
                  <p className="text-sm font-medium text-gray-700">
                    {t('components.project.template.layer.LayerParametrizeScriptingModal.name', {
                      context: scriptType
                    })}
                  </p>
                  <p className="text-sm text-gray-500">
                    {t('components.project.template.layer.LayerParametrizeScriptingModal.description', {
                      context: scriptType
                    })}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      <form
        onSubmit={e => {
          e.preventDefault();
          editScript ? onEdit() : onSave();
        }}
      >
        {!selectedScriptType && filteredScriptTypes.length === 0 && (
          <div className="px-6 py-14 text-center text-sm sm:px-14">
            <ExclamationCircleIcon className="mx-auto h-6 w-6 text-gray-400" />
            <p className="mt-4 font-semibold text-gray-900">{t('general.common.noResultsFound')}</p>
            <p className="mt-2 text-gray-500">
              {t('components.project.template.layer.LayerParametrizeScriptingModal.noScriptsFound')}
            </p>
          </div>
        )}
        {selectedScriptType && (
          <div className="group flex cursor-default select-none p-3 pb-6">
            <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
              {getScriptIcon(selectedScriptType as ScriptType, { className: 'h-6 w-6 text-white' })}
            </div>
            <div className="ml-4 flex-auto">
              <p className="text-sm font-medium text-gray-700">
                {t('components.project.template.layer.LayerParametrizeScriptingModal.name', {
                  context: selectedScriptType
                })}
              </p>
              <p className="text-sm text-gray-500">
                {t('components.project.template.layer.LayerParametrizeScriptingModal.description', {
                  context: selectedScriptType
                })}
              </p>
            </div>
          </div>
        )}
        {/* TIME SCULPTING SCRIPTS */}
        {selectedScriptType === ScriptType.CROP && (
          <CropScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.CROP ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.EXTEND && (
          <ExtendScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.EXTEND ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.SET_DURATION && (
          <SetDurationScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.SET_DURATION ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.SHIFT_IN && (
          <TimeActionScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            compositions={compositions}
            scriptType={ScriptType.SHIFT_IN}
            editScript={editScript?.scriptType === ScriptType.SHIFT_IN ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.SHIFT_OUT && (
          <TimeActionScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            compositions={compositions}
            scriptType={ScriptType.SHIFT_OUT}
            editScript={editScript?.scriptType === ScriptType.SHIFT_OUT ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.TRIM_IN && (
          <TimeActionScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            compositions={compositions}
            scriptType={ScriptType.TRIM_IN}
            editScript={editScript?.scriptType === ScriptType.TRIM_IN ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.TRIM_OUT && (
          <TimeActionScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            compositions={compositions}
            scriptType={ScriptType.TRIM_OUT}
            editScript={editScript?.scriptType === ScriptType.TRIM_OUT ? editScript : undefined}
          />
        )}
        {selectedScriptType === ScriptType.STRETCH && (
          <StretchScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            compositions={compositions}
            editScript={editScript?.scriptType === ScriptType.STRETCH ? editScript : undefined}
          />
        )}
        {/* VIDEO_GENIUS SCRIPTS */}
        {selectedScriptType === ScriptType.SCENE_MANAGEMENT && (
          <SceneManagementForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.SCENE_MANAGEMENT ? editScript : undefined}
            item={item}
          />
        )}
        {/* ASSET SCRIPTS */}
        {selectedScriptType === ScriptType.IMAGE_SEQUENCE_LOADER && (
          <ImageSequenceLoaderForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.IMAGE_SEQUENCE_LOADER ? editScript : undefined}
          />
        )}

        {selectedScriptType === ScriptType.MEDIA_AUTO_SCALE && (
          <MediaAutoScaleScriptingForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.MEDIA_AUTO_SCALE ? editScript : undefined}
          />
        )}

        {/* GENERAL SCRIPTS */}
        {selectedScriptType === ScriptType.LAYER_MANAGEMENT && (
          <LayerManagementForm
            setFormValid={setFormValid}
            setFormScript={setFormScript}
            editScript={editScript?.scriptType === ScriptType.LAYER_MANAGEMENT ? editScript : undefined}
          />
        )}

        {!selectedScriptType && (
          <div className="flex justify-end p-3">
            <Button
              secondary
              onClick={closeModal}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {t('general.action.cancel')}
            </Button>
          </div>
        )}
        {selectedScriptType && !editScript && (
          <div className="flex justify-between p-3">
            <Button secondary onClick={() => setSelectedScriptType(null)}>
              {t('general.action.back')}
            </Button>
            <Button
              type="submit"
              disabled={!formValid}
              className={classNames(!formValid && 'cursor-not-allowed opacity-50')}
            >
              {t('general.action.save')}
            </Button>
          </div>
        )}
        {editScript && (
          <div className="flex justify-between p-3">
            <Button secondary onClick={closeModal}>
              {t('general.action.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={!formValid}
              className={classNames(!formValid && 'cursor-not-allowed opacity-50')}
            >
              {t('general.action.save')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export const LayerParametrizeScriptingModal = ({
  show,
  closeModal,
  selectedScripts,
  setSelectedScripts,
  editScript,
  compositions,
  item
}: LayerParametrizeScriptingModalProps) => {
  return (
    <Modal visible={show} close={closeModal} size="normal" removeOuterPadding>
      <LayerParametrizeScriptingModalContent
        closeModal={closeModal}
        selectedScripts={selectedScripts}
        setSelectedScripts={setSelectedScripts}
        editScript={editScript}
        compositions={compositions}
        item={item}
      />
    </Modal>
  );
};
