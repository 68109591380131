import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { AllAutoGenerateTemplatesForm, Button, Modal } from '@src/components';
import { useAutoGenerateProjectTemplates } from '@src/hooks';
import { AnyAutoCreateTemplateDto, AutoCreateTemplateType } from '@src/models';

import { PrefixAutoGenerateTemplatesForm } from './PrefixAutoGenerateTemplatesForm';

const getAutoGenerateIcon = (type: AutoCreateTemplateType, props: React.ComponentProps<'p'>) => {
  switch (type) {
    case AutoCreateTemplateType.all:
      return (
        <p {...props}>
          <span className="text-xl">A</span>
        </p>
      );
    case AutoCreateTemplateType.prefix:
      return (
        <p {...props}>
          <span className="text-xl">P</span>
        </p>
      );
  }
};

type AutoGenerateTemplatesModalProps = {
  visible: boolean;
  close: () => void;
  projectId: string;
};

const AutoGenerateTemplatesModalContent = ({ close, projectId }: Omit<AutoGenerateTemplatesModalProps, 'visible'>) => {
  const { t } = useTranslation();
  const { mutateAsync: generateTemplates, isLoading } = useAutoGenerateProjectTemplates();

  const [type, setType] = useState<AutoCreateTemplateType>();
  const [data, setData] = useState<AnyAutoCreateTemplateDto>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!type || !data) return;

    try {
      await generateTemplates({ projectId, autoCreateTemplateDto: data });
    } finally {
      close();
    }
    close();
  };

  const types = Object.values(AutoCreateTemplateType);

  const formComponents = {
    [AutoCreateTemplateType.all]: AllAutoGenerateTemplatesForm,
    [AutoCreateTemplateType.prefix]: PrefixAutoGenerateTemplatesForm
  };
  const FormComponent = type ? formComponents[type] : undefined;

  return (
    <>
      {!type && (
        <div className="p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.title')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.description')}
          </p>
        </div>
      )}
      {!type && types.length > 0 && (
        <ul className="max-h-96 min-h-[200px] scroll-py-3 overflow-y-auto p-3" id="options" role="listbox">
          {types.map((tt, index) => (
            <li
              className="group flex cursor-pointer select-none rounded-xl p-3 hover:bg-gray-100"
              key={index}
              id={`option-${index}`}
              role="option"
              tabIndex={-1}
              aria-selected={type === tt}
              onClick={() => setType(tt)}
            >
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
                {getAutoGenerateIcon(tt, {
                  className: 'h-6 w-6 text-white flex items-center justify-center'
                })}
              </div>
              <div className="ml-4 flex-auto">
                <p className="text-sm font-medium text-gray-700">
                  {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.type', {
                    context: tt
                  })}
                </p>
                <p className="text-sm text-gray-500">
                  {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.typeDesc', {
                    context: tt
                  })}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
      <form onSubmit={handleSubmit}>
        {!type && types.length === 0 && (
          <div className="px-6 py-14 text-center text-sm sm:px-14">
            <ExclamationCircleIcon className="mx-auto h-6 w-6 text-gray-400" />
            <p className="mt-4 font-semibold text-gray-900">{t('general.common.noResultsFound')}</p>
            <p className="mt-2 text-gray-500">
              {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.noResultsFoundDesc')}
            </p>
          </div>
        )}
        {type && (
          <div className="group flex cursor-default select-none p-3 pb-6">
            <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500">
              {getAutoGenerateIcon(type, {
                className: 'h-6 w-6 text-white flex justify-center items-center'
              })}
            </div>
            <div className="ml-4 flex-auto">
              <p className="text-sm font-medium text-gray-700">
                {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.type', {
                  context: type
                })}
              </p>
              <p className="text-sm text-gray-500">
                {t('components.project.autoGenerateTemplates.AutoGenerateTemplatesModal.typeDesc', {
                  context: type
                })}
              </p>
            </div>
          </div>
        )}
        {!type && (
          <div className="flex justify-end p-3">
            <Button
              secondary
              onClick={close}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {t('general.action.cancel')}
            </Button>
          </div>
        )}

        {type && FormComponent && <FormComponent setData={setData} projectId={projectId} />}

        {type && (
          <div className="flex justify-between p-3">
            <Button
              secondary
              onClick={() => {
                setType(undefined);
                setData(undefined);
              }}
            >
              {t('general.action.back')}
            </Button>
            <Button type="submit" loading={isLoading}>
              {t('general.action.generate')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export const AutoGenerateTemplatesModal = ({ visible, close, projectId }: AutoGenerateTemplatesModalProps) => {
  return (
    <Modal visible={visible} close={close} size="normal" removeOuterPadding>
      <AutoGenerateTemplatesModalContent projectId={projectId} close={close} />
    </Modal>
  );
};
