import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Loading, NotFound, TemplateLayersForm, TemplateNameCompositionForm } from '@src/components';
import {
  useCreateProjectTemplate,
  useCreateTemplateReducer,
  useGetProjectDetails,
  useGetProjectMetadata
} from '@src/hooks';
import { CompositionAeItem, Layer, TemplateDto } from '@src/models';
import * as routes from '@src/routes';

import { getRootComposition } from './utils';

export const TemplateCreate = ({ projectId, step }: { projectId: string; step?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // api calls
  const { isLoading: projectLoading, data: project } = useGetProjectDetails(projectId);
  const { isLoading: metaLoading, data: meta } = useGetProjectMetadata(projectId, project?.analyzed || false);
  const { isLoading: saveTemplateLoading, mutateAsync: saveTemplate } = useCreateProjectTemplate();

  const loading = projectLoading || metaLoading;

  const { state, updateTemplate } = useCreateTemplateReducer();
  const template = state[projectId] || {};

  const toLayers = (t: TemplateDto, c: CompositionAeItem) => {
    updateTemplate(projectId, {
      ...t,
      renderingCompositionId: c.id
    });
    const path = generatePath(routes.PROJECT_TEMPLATE_CREATE, { projectId, step: 'layers' });
    navigate(path);
  };

  const onLayersUpdate = (layers: Partial<Layer>[]) =>
    updateTemplate(projectId, {
      ...template,
      layers
    });

  const onSave = async () => {
    const project = await saveTemplate({ projectId, template });
    if (project) {
      const templateId = project.templates[project.templates.length - 1].id;
      navigate(generatePath(routes.PROJECT_TEMPLATE_DETAILS, { projectId, templateId }));
    }
  };

  const rootComposition = meta ? getRootComposition(meta, template.renderingCompositionId) : undefined;

  return (
    <>
      {loading && (
        <div className="h-full w-full place-content-center">
          <Loading title={t('components.project.template.TemplateCreate.loading')} />
        </div>
      )}
      {!loading && (
        <>
          {!(project && meta) && <NotFound title={t('components.project.template.common.empty')} />}
          {project && meta && (
            <>
              {step === undefined && (
                <TemplateNameCompositionForm
                  template={template}
                  composition={rootComposition}
                  meta={meta}
                  onNext={toLayers}
                />
              )}
              {step === 'layers' && rootComposition && (
                <TemplateLayersForm
                  projectId={projectId}
                  template={template}
                  composition={rootComposition}
                  onSave={onSave}
                  onUpdate={onLayersUpdate}
                  saveInProgress={saveTemplateLoading}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
