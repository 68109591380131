import { useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  Button,
  CaptionsPageHelpLinks,
  Checkbox,
  HelpNotification,
  IntegrationsPageHelpLinks,
  MediaInput,
  OutputFormatPageHelpLinks,
  PostEncoding,
  ProjectFilesPageHelpLinks,
  ThumbnailsPageHelpLinks,
  WatermarkPageHelpLinks,
  WebhookPageHelpLinks
} from '@src/components/';
import {
  CaptionsPosition,
  CaptionsStyle,
  OutputModule,
  PostEncode,
  RenderOptionsDto,
  SettingsTemplate
} from '@src/models';

type RenderAdvancedOptionsFormPropsBase = React.ComponentPropsWithRef<'div'> & {
  onSave: (advancedOptions: RenderOptionsDto) => void;
  advancedOptions?: RenderOptionsDto;
  isBatchRender?: boolean;
  onValidation: (key: string) => (url: string, valid: boolean) => void;
};

type WithoutFormButtons = RenderAdvancedOptionsFormPropsBase & {
  onBack?: () => void;
  canSave?: boolean;
  formButtons?: never;
};

type WithFormButtons = RenderAdvancedOptionsFormPropsBase & {
  onBack?: never;
  canSave?: never;
  formButtons: React.ReactNode;
};

export const RenderAdvancedOptionsForm = ({
  onBack,
  onSave,
  advancedOptions,
  isBatchRender,
  onValidation,
  canSave,
  formButtons,
  ...rest
}: WithFormButtons | WithoutFormButtons) => {
  const { t } = useTranslation();

  const [postEncoding, setPostEncoding] = useState<PostEncode | undefined>(advancedOptions?.outputFormat?.postEncoding);
  const [outputModule, setOutputModule] = useState<OutputModule | undefined>(
    advancedOptions?.outputFormat?.outputModule
  );
  const [settingsTemplate, setSettingsTemplate] = useState<SettingsTemplate | undefined>(
    advancedOptions?.outputFormat?.settingsTemplate
  );
  const [attachment, setAttachment] = useState(advancedOptions?.outputFormat?.attachment || false);
  const [attachmentFileName, setAttachmentFileName] = useState(advancedOptions?.outputFormat?.attachmentFileName || '');
  const [webhookUrl, setWebhookUrl] = useState<string>(advancedOptions?.webhook?.url || '');
  const [webhookPassthrough, setWebhookPassThrough] = useState<string>(advancedOptions?.webhook?.passthrough || '');
  const [onFailure, setOnFailure] = useState<boolean>(advancedOptions?.webhook?.onFailure || false);
  const [onInvalid, setOnInvalid] = useState<boolean>(advancedOptions?.webhook?.onInvalid || false);
  const [frequencySeconds, setFrequencySeconds] = useState<number>(
    advancedOptions?.options?.thumbnails?.frequencySeconds || 0
  );
  const [atSeconds, setAtSeconds] = useState<string[]>(
    advancedOptions?.options?.thumbnails?.atSeconds?.toString().split(',') || []
  );
  const [skipAllIntegrations, setAllSkipIntegrations] = useState<boolean>(
    advancedOptions?.options?.integrations?.skipAll || false
  );
  const [integrationsPassthrough, setIntegrationsPassthrough] = useState<string>(
    advancedOptions?.options?.integrations?.passthrough || ''
  );
  const [watermarkUrl, setWatermarkUrl] = useState<string>(advancedOptions?.options?.watermark?.url || '');
  const [watermarkEncodingParams, setWatermarkEncodingParams] = useState<string>(
    advancedOptions?.options?.watermark?.encodingParamsLine || ''
  );
  const [uploadProjectFilesEnabled, setUploadProjectFilesEnabled] = useState<boolean>(
    !!advancedOptions?.options?.projectFiles?.uploadEnabled
  );

  // captions
  const [srtFileUrl, setSrtFileUrl] = useState<string | undefined>(advancedOptions?.options?.captions?.srtFileUrl);
  const [captionsStyle, setCaptionsStyle] = useState<CaptionsStyle | undefined>(
    advancedOptions?.options?.captions?.captionsStyle
  );
  const [captionsPosition, setCaptionsPosition] = useState<CaptionsPosition | undefined>(
    advancedOptions?.options?.captions?.captionsPosition
  );

  const [showOutput, setShowOutput] = useState<boolean>(false);
  const [showWebhook, setShowWebhook] = useState<boolean>(false);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const [showIntegrations, setShowIntegrations] = useState<boolean>(false);
  const [showWatermark, setShowWatermark] = useState(false);
  const [showProjectSection, setShowProjectSection] = useState(false);
  const [showCaptionsSection, setShowCaptionsSection] = useState(false);

  const drawOnlyOne = (show: boolean, setShow: (show: boolean) => void) => {
    setShowOutput(false);
    setShowWebhook(false);
    setShowThumbnails(false);
    setShowIntegrations(false);
    setShowWatermark(false);
    setShowProjectSection(false);
    setShowCaptionsSection(false);
    setShow(!show);
  };

  const checkOptions = () => {
    let allOptions: RenderOptionsDto = {};

    if (attachment) {
      allOptions = {
        ...allOptions,
        outputFormat: {
          attachment: true,
          attachmentFileName: attachmentFileName ? attachmentFileName : undefined
        }
      };
    }

    if (settingsTemplate) {
      allOptions = {
        ...allOptions,
        outputFormat: {
          ...allOptions.outputFormat,
          settingsTemplate
        }
      };
    }

    if (outputModule) {
      allOptions = {
        ...allOptions,
        outputFormat: {
          ...allOptions.outputFormat,
          outputModule
        }
      };
    }

    if (postEncoding) {
      allOptions = {
        ...allOptions,
        outputFormat: {
          ...allOptions.outputFormat,
          postEncoding: postEncoding
        }
      };
    }

    if (webhookUrl) {
      allOptions = {
        ...allOptions,
        webhook: {
          url: webhookUrl,
          passthrough: webhookPassthrough ? webhookPassthrough : undefined,
          onFailure,
          onInvalid
        }
      };
    }

    if (frequencySeconds > 0) {
      allOptions = {
        ...allOptions,
        options: {
          thumbnails: {
            frequencySeconds
          }
        }
      };
    }

    if (atSeconds.length > 0) {
      allOptions = {
        ...allOptions,
        options: {
          thumbnails: {
            atSeconds: atSeconds.flatMap(s => {
              try {
                return parseFloat(s);
              } catch (e) {
                return [];
              }
            })
          }
        }
      };
    }

    if (skipAllIntegrations || integrationsPassthrough) {
      let integrations = {};

      if (skipAllIntegrations) {
        integrations = {
          ...integrations,
          skipAll: true
        };
      }

      if (integrationsPassthrough) {
        integrations = {
          ...integrations,
          passthrough: integrationsPassthrough
        };
      }

      allOptions = {
        ...allOptions,
        options: {
          ...allOptions.options,
          integrations
        }
      };
    }

    if (watermarkUrl) {
      allOptions = {
        ...allOptions,
        options: {
          ...allOptions.options,
          watermark: {
            url: watermarkUrl,
            encodingParamsLine: watermarkEncodingParams ? watermarkEncodingParams : undefined
          }
        }
      };
    }

    if (uploadProjectFilesEnabled) {
      allOptions = {
        ...allOptions,
        options: {
          ...allOptions.options,
          projectFiles: {
            uploadEnabled: uploadProjectFilesEnabled
          }
        }
      };
    }

    // this is a case when we are not coming from templateDefaultOptions
    // then we need srtFileUrl
    if (srtFileUrl) {
      allOptions = {
        ...allOptions,
        options: {
          ...allOptions.options,
          captions: {
            srtFileUrl: srtFileUrl,
            captionsStyle: captionsStyle,
            captionsPosition: captionsPosition
          }
        }
      };
    }

    return allOptions;
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        const options = checkOptions();
        onSave(options);
        onBack?.();
      }}
    >
      <div className={classNames('space-y-6 divide-y divide-gray-200 bg-white px-4 py-6 sm:px-6', rest.className)}>
        {/* Output format */}
        <div>
          <div className="flex-column flex">
            <div className="flex-grow cursor-pointer" onClick={() => drawOnlyOne(showOutput, setShowOutput)}>
              <h3 className="flex font-medium leading-6 text-gray-900">{t('components.render.common.outputFormat')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.outputFormatDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showOutput ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={OutputFormatPageHelpLinks} />
            </div>
            {showOutput ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showOutput, setShowOutput)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showOutput, setShowOutput)}
              />
            )}
          </div>

          {showOutput && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="settingsTemplate" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.settingsTemplate')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.settingsTemplateDescription')}
                </p>
                <select
                  id="settingTemplate"
                  name="settingTemplate"
                  autoComplete="settingTemplate"
                  className={classNames(
                    'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                    !settingsTemplate && 'text-gray-500'
                  )}
                  value={settingsTemplate || ''}
                  onChange={e => setSettingsTemplate(Object.values(SettingsTemplate).find(st => st === e.target.value))}
                >
                  <option key="" value="">
                    {t('components.render.RenderAdvancedOptionsForm.settingsTemplateSelect')}
                  </option>
                  {Object.keys(SettingsTemplate).map(st => (
                    <option key={st} value={st}>
                      {t('components.render.common.settingsTemplate', { context: st })}
                    </option>
                  ))}
                </select>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="outputModule" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.outputModule')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.outputModuleDescription')}
                </p>
                <select
                  id="outputModule"
                  name="outputModule"
                  autoComplete="outputModule"
                  className={classNames(
                    'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                    !outputModule && 'text-gray-500'
                  )}
                  value={outputModule || ''}
                  onChange={e => setOutputModule(Object.values(OutputModule).find(om => om === e.target.value))}
                >
                  <option key="" value="">
                    {t('components.render.RenderAdvancedOptionsForm.outputModuleSelect')}
                  </option>
                  {Object.keys(OutputModule).map(om => (
                    <option key={om} value={om}>
                      {t('components.render.common.outputModule', { context: om })}
                    </option>
                  ))}
                </select>
              </div>

              <PostEncoding onPostEncodeTypeChange={setPostEncoding} postEncoding={postEncoding} />

              <Checkbox
                id={'attachment'}
                checked={attachment}
                onChange={e => {
                  const enabled = e.target.checked;
                  setAttachment(enabled);
                }}
                label={t('components.render.RenderAdvancedOptionsForm.attachment')}
                description={t('components.render.RenderAdvancedOptionsForm.attachmentDescription')}
                className={'sm:col-span-6'}
              />

              <div className={classNames('sm:col-span-6', !attachment && 'opacity-50')}>
                <label htmlFor="attachmentFileName" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.attachmentFileName')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.attachmentFileNameDescription')}
                </p>
                <input
                  disabled={!attachment}
                  type="text"
                  name="attachmentFileName"
                  id="attachmentFileName"
                  className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm"
                  value={attachmentFileName}
                  onChange={e => {
                    const value = e.target.value.replace(/[^\w\s\-{}]/g, '');
                    setAttachmentFileName(value);
                  }}
                  placeholder="e.g.: my_cool_video"
                />
              </div>
            </div>
          )}
        </div>
        {/* Webhook */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div className="flex-grow cursor-pointer" onClick={() => drawOnlyOne(showWebhook, setShowWebhook)}>
              <h3 className="font-medium leading-6 text-gray-900">{t('components.render.common.webhook')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.webhookDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showWebhook ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={WebhookPageHelpLinks} />
            </div>
            {showWebhook ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showWebhook, setShowWebhook)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showWebhook, setShowWebhook)}
              />
            )}
          </div>
          {showWebhook && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.webhookUrl')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.webhookUrlDescription')}
                </p>
                <input
                  type="url"
                  name="url"
                  id="url"
                  autoComplete="url"
                  className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={webhookUrl}
                  onChange={e => setWebhookUrl(e.target.value.trim())}
                />
              </div>

              <div className={classNames('sm:col-span-6', (!webhookUrl || isBatchRender) && 'opacity-50')}>
                <label htmlFor="passthrough" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.passthrough')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.webhookPassthroughDescription')}
                </p>
                <input
                  type="text"
                  name="passthrough"
                  id="passthrough"
                  className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm"
                  disabled={!webhookUrl || isBatchRender}
                  value={webhookPassthrough}
                  onChange={e => setWebhookPassThrough(e.target.value)}
                />
              </div>

              <Checkbox
                id={'onFailure'}
                checked={onFailure}
                onChange={e => setOnFailure(e.target.checked)}
                label={t('components.render.RenderAdvancedOptionsForm.webhookOnFailure')}
                description={t('components.render.RenderAdvancedOptionsForm.webhookOnFailureDescription')}
                disabled={!webhookUrl}
                className={'sm:col-span-6'}
              />

              <Checkbox
                id={'onInvalid'}
                checked={onInvalid}
                onChange={e => setOnInvalid(e.target.checked)}
                label={t('components.render.RenderAdvancedOptionsForm.webhookOnInvalid')}
                description={t('components.render.RenderAdvancedOptionsForm.webhookOnInvalidDescription')}
                disabled={!webhookUrl}
                className={'sm:col-span-6'}
              />
            </div>
          )}
        </div>
        {/* Integrations */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div
              className="flex-grow cursor-pointer"
              onClick={() => drawOnlyOne(showIntegrations, setShowIntegrations)}
            >
              <h3 className="font-medium leading-6 text-gray-900">{t('general.common.integrations')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.integrationsDescriptions')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showIntegrations ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={IntegrationsPageHelpLinks} />
            </div>
            {showIntegrations ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showIntegrations, setShowIntegrations)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showIntegrations, setShowIntegrations)}
              />
            )}
          </div>
          {showIntegrations && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <Checkbox
                id={'skipIntegrations'}
                checked={skipAllIntegrations}
                onChange={e => setAllSkipIntegrations(e.target.checked)}
                label={t('components.render.common.integrationsSkipAll')}
                description={t('components.render.RenderAdvancedOptionsForm.integrationsSkipAllDescription')}
                className={'sm:col-span-6'}
              />

              <div className={classNames('sm:col-span-6', isBatchRender && 'opacity-50')}>
                <label htmlFor="integrations-passthrough" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.passthrough')}
                </label>
                <p className="text-sm text-gray-500">
                  <Trans
                    i18nKey={'components.render.RenderAdvancedOptionsForm.integrationsPassthroughDescription'}
                    tOptions={{ phrase: 'video delivery' }}
                  >
                    Optional passthrough value that will be available in the
                    <span className="font-medium tracking-wider">{'video delivery'}</span> integration types.
                  </Trans>
                </p>
                <input
                  type="text"
                  name="integrations-passthrough"
                  id="integrations-passthrough"
                  className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm"
                  value={integrationsPassthrough}
                  onChange={e => setIntegrationsPassthrough(e.target.value)}
                  disabled={isBatchRender}
                />
              </div>
            </div>
          )}
        </div>
        {/* Captions */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div
              className="flex-grow cursor-pointer"
              onClick={() => drawOnlyOne(showCaptionsSection, setShowCaptionsSection)}
            >
              <h3 className="font-medium leading-6 text-gray-900">
                {t('components.render.RenderAdvancedOptionsForm.captionsSection')}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.captionsSectionDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showCaptionsSection ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={CaptionsPageHelpLinks} />
            </div>
            {showCaptionsSection ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showCaptionsSection, setShowCaptionsSection)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showCaptionsSection, setShowCaptionsSection)}
              />
            )}
          </div>
          {showCaptionsSection && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="srtFileUrl" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.captionsSrtUrl')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.captionsSrtUrlDescription')}
                </p>
                <input
                  type="url"
                  name="srtFileUrl"
                  id="srtFileUrl"
                  autoComplete="url"
                  className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={srtFileUrl || ''}
                  onChange={e => setSrtFileUrl(e.target.value.trim())}
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="captionsStyle" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.captionsStyle')}
                </label>
                <p className="text-sm text-gray-500">
                  <Trans
                    i18nKey={'components.render.RenderAdvancedOptionsForm.captionsStyleDescription'}
                    tOptions={{ here: 'here' }}
                  >
                    Style of the captions used in the video. Check how they look
                    <a
                      className="text-indigo-600 hover:text-indigo-500"
                      href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#captions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </Trans>
                </p>
                <select
                  disabled={!srtFileUrl}
                  id="captionsStyle"
                  name="captionsStyle"
                  autoComplete="captionsStyle"
                  className={classNames(
                    'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm',
                    !captionsStyle && 'text-gray-500'
                  )}
                  value={captionsStyle || ''}
                  onChange={e => setCaptionsStyle(Object.values(CaptionsStyle).find(cs => cs === e.target.value))}
                >
                  <option key="" value="">
                    {t('components.render.RenderAdvancedOptionsForm.captionsStyleSelect')}
                  </option>
                  {Object.keys(CaptionsStyle).map(cs => (
                    <option key={cs} value={cs}>
                      {t('components.render.RenderAdvancedOptionsForm.captionsStyle', { context: cs })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="captionsPosition" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.captionsPosition')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.captionsPositionDescription')}
                </p>
                <select
                  disabled={!srtFileUrl}
                  id="captionsPosition"
                  name="captionsPosition"
                  autoComplete="captionsPosition"
                  className={classNames(
                    'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm',
                    !captionsPosition && 'text-gray-500'
                  )}
                  value={captionsPosition || ''}
                  onChange={e => setCaptionsPosition(Object.values(CaptionsPosition).find(cp => cp === e.target.value))}
                >
                  <option key="" value="">
                    {t('components.render.RenderAdvancedOptionsForm.captionsPositionSelect')}
                  </option>
                  {Object.keys(CaptionsPosition).map(cp => (
                    <option key={cp} value={cp}>
                      {t('components.render.RenderAdvancedOptionsForm.captionsPosition', { context: cp })}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {/* Thumbnails */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div className="flex-grow cursor-pointer" onClick={() => drawOnlyOne(showThumbnails, setShowThumbnails)}>
              <h3 className="font-medium leading-6 text-gray-900">{t('components.render.common.thumbnails')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.thumbnailsDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showThumbnails ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={ThumbnailsPageHelpLinks} />
            </div>
            {showThumbnails ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showThumbnails, setShowThumbnails)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showThumbnails, setShowThumbnails)}
              />
            )}
          </div>
          {showThumbnails && (
            <div className="mt-6 space-y-6">
              <div className={classNames('sm:col-span-6', atSeconds.length > 0 && 'opacity-50')}>
                <label htmlFor="frequencySeconds" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.frequencySeconds')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.frequencySecondsDescription')}
                </p>
                <input
                  disabled={atSeconds.length > 0}
                  type="number"
                  name="frequencySeconds"
                  id="frequencySeconds"
                  min="1"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm "
                  value={(frequencySeconds > 0 && frequencySeconds?.toString()) || ''}
                  onChange={e =>
                    e.target.value.length > 0 ? setFrequencySeconds(parseInt(e.target.value)) : setFrequencySeconds(0)
                  }
                  onKeyDown={e => {
                    const keyNumber = parseInt(e.key);
                    if (!keyNumber && keyNumber !== 0 && e.key !== 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className={classNames('sm:col-span-6', frequencySeconds > 0 && 'opacity-50')}>
                <label htmlFor="atSeconds" className="block text-sm font-medium text-gray-700">
                  {t('components.render.RenderAdvancedOptionsForm.atSeconds')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.atSecondsDescription')}
                </p>
                <input
                  disabled={frequencySeconds > 0}
                  type="text"
                  name="atSeconds"
                  id="atSeconds"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm "
                  placeholder="e.g.: 1.4, 2.3, 4"
                  value={atSeconds.join(',')}
                  onChange={e => {
                    if (e.target.value === '') {
                      setAtSeconds([]);
                    } else {
                      setAtSeconds(e.target.value.split(','));
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* Watermark */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div className="flex-grow cursor-pointer" onClick={() => drawOnlyOne(showWatermark, setShowWatermark)}>
              <h3 className="font-medium leading-6 text-gray-900">{t('components.render.common.watermark')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.watermarkDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showWatermark ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={WatermarkPageHelpLinks} />
            </div>
            {showWatermark ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showWatermark, setShowWatermark)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showWatermark, setShowWatermark)}
              />
            )}
          </div>
          {showWatermark && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.watermarkUrl')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.watermarkUrlDescription')}
                </p>
                <MediaInput
                  id="watermarkUrl"
                  className="mt-2"
                  value={watermarkUrl}
                  onFieldUpdate={(url: string) => setWatermarkUrl(url)}
                  onValidation={onValidation('watermarkUrl')}
                />
              </div>

              <div className={classNames('sm:col-span-6', !watermarkUrl && 'opacity-50')}>
                <label htmlFor="encodingParams" className="block text-sm font-medium text-gray-700">
                  {t('components.render.common.encodingParams')}
                </label>
                <p className="text-sm text-gray-500">
                  {t('components.render.RenderAdvancedOptionsForm.encodingParamsDescription')}
                </p>
                <div className="flex items-center">
                  <input
                    type="text"
                    name="encodingParam"
                    id="encodingParam"
                    key="encodingParam"
                    autoComplete="encodingParam"
                    placeholder="e.g.: -filter_complex [1]scale=iw*0.2:-1,format=rgba,colorchannelmixer=aa=0.8[watermark];[0][watermark]overlay=10:10[out] -map [out]"
                    className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed sm:text-sm"
                    disabled={!watermarkUrl}
                    value={watermarkEncodingParams}
                    onChange={e => setWatermarkEncodingParams(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Project */}
        <div className="pt-8">
          <div className="flex-column flex">
            <div
              className="flex-grow cursor-pointer"
              onClick={() => drawOnlyOne(showProjectSection, setShowProjectSection)}
            >
              <h3 className="font-medium leading-6 text-gray-900">
                {t('components.render.RenderAdvancedOptionsForm.projectSection')}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.render.RenderAdvancedOptionsForm.projectSectionDescription')}
              </p>
            </div>
            <div className={classNames('ml-4 mr-2 self-center', showProjectSection ? 'visible' : 'invisible')}>
              <HelpNotification type="badge" links={ProjectFilesPageHelpLinks} />
            </div>
            {showProjectSection ? (
              <MinusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showProjectSection, setShowProjectSection)}
              />
            ) : (
              <PlusIcon
                className="h-6 w-6 shrink-0 cursor-pointer self-center text-gray-500 hover:text-indigo-500"
                onClick={() => drawOnlyOne(showProjectSection, setShowProjectSection)}
              />
            )}
          </div>
          {showProjectSection && (
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <Checkbox
                  id={'projectFiles.uploadEnabled'}
                  checked={uploadProjectFilesEnabled}
                  onChange={e => setUploadProjectFilesEnabled(e.target.checked)}
                  label={t('components.render.RenderAdvancedOptionsForm.projectFilesUpload')}
                  description={t('components.render.RenderAdvancedOptionsForm.projectFilesUploadDescription')}
                  className={'sm:col-span-6'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!formButtons && (
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <Button secondary className="mr-3" onClick={onBack}>
            {t('general.action.back')}
          </Button>
          <Button disabled={!canSave} type="submit">
            {t('general.action.save')}
          </Button>
        </div>
      )}
      {formButtons}
    </form>
  );
};
