import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Transition } from '@headlessui/react';
import {
  CheckIcon as CheckImage,
  ChevronUpDownIcon as SelectorImage,
  SignalIcon,
  XMarkIcon as XImage
} from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { randId } from '@src/utils';

export type ComboboxItem<T> = {
  badge?: string;
  label?: string;
  customLabel?: ReactNode;
  item: T;
  selected?: boolean;
};

export type ComboboxProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  data: ComboboxItem<T>[] | undefined;
  disabled?: boolean;
  onSelectionChange?: (item?: T) => void;
  notSelectedLabel?: string;
  clearDisabled?: boolean;
  hasSearch?: boolean;
  loading?: boolean;
  customLabel?: ReactNode;
};

export const PlainlyCombobox = <T,>({
  data,
  disabled,
  notSelectedLabel,
  onSelectionChange,
  clearDisabled,
  hasSearch,
  loading,
  ...rest
}: ComboboxProps<T>) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const selectedOption = data?.find(item => item.selected);

  const filteredData = data?.filter(d => {
    if (search) {
      return d.label?.toLowerCase().includes(search.toLowerCase());
    }
    return true;
  });

  useEffect(() => {
    if (selectedOption) setSearch('');
  }, [selectedOption]);

  disabled = disabled || data?.length === 0;

  return (
    <div {...rest}>
      <div className={classNames('relative cursor-pointer', loading && 'cursor-not-allowed opacity-50')}>
        {hasSearch ? (
          <div className="relative w-full rounded-md border border-gray-300 bg-white shadow-sm">
            {open && !disabled && (
              <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
              </span>
            )}
            <input
              onKeyDown={e => {
                if (search && e.key === 'Escape') {
                  setSearch('');
                  onSelectionChange && onSelectionChange();
                  setOpen(false);
                  e.currentTarget.blur();
                }
              }}
              onClick={() => setOpen(!open)}
              onBlur={() => setOpen(false)}
              disabled={disabled}
              className={classNames(
                'block w-full cursor-pointer truncate rounded-md border-transparent py-2 pr-10 text-left focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-100 sm:text-sm',
                open ? ' pl-9' : 'pl-3',
                disabled ? 'text-gray-300' : !selectedOption && 'text-gray-500'
              )}
              value={!selectedOption && !search && !open ? notSelectedLabel || '-' : selectedOption?.label || search}
              onChange={e => {
                setSearch(e.target.value);
                onSelectionChange && onSelectionChange();
              }}
            />
            {selectedOption?.badge && (
              <span className="absolute inset-y-2 right-12 inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
                {selectedOption?.badge}
              </span>
            )}
            {!clearDisabled && (
              <span
                onClick={e => {
                  setSearch('');
                  onSelectionChange && onSelectionChange();
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={classNames(
                  'pointer-events-auto absolute inset-y-0 right-5 flex items-center pr-2 text-gray-400 hover:text-gray-800',
                  !selectedOption && 'hidden'
                )}
              >
                <XImage className="h-4 w-4 hover:text-gray-800" />
              </span>
            )}
            {loading && (
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
                <SignalIcon className={classNames('h-5 w-5 animate-spin')} />
              </span>
            )}
            {!loading && (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorImage className={classNames('h-5 w-5', disabled ? 'text-gray-300' : 'text-gray-400')} />
              </span>
            )}
          </div>
        ) : (
          <button
            tabIndex={0}
            onClick={() => setOpen(!open)}
            onBlur={() => setOpen(false)}
            type="button"
            disabled={disabled}
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            className="relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-100"
          >
            <span
              className={classNames('block truncate', disabled ? 'text-gray-300' : !selectedOption && 'text-gray-500')}
            >
              {selectedOption?.customLabel || selectedOption?.label || notSelectedLabel || '-'}
            </span>
            {selectedOption?.badge && (
              <span className="absolute inset-y-2 right-12 inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
                {selectedOption?.badge}
              </span>
            )}
            {!clearDisabled && (
              <span
                onClick={e => {
                  onSelectionChange && onSelectionChange();
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={classNames(
                  'pointer-events-auto absolute inset-y-0 right-5 flex items-center pr-2 text-gray-400 hover:text-gray-800',
                  !selectedOption && 'hidden'
                )}
              >
                <XImage className="h-4 w-4 hover:text-gray-800" />
              </span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorImage className={classNames('h-5 w-5', disabled ? 'text-gray-300' : 'text-gray-400')} />
            </span>
          </button>
        )}
        <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="absolute z-20 mt-1 w-full rounded-md bg-white shadow-lg">
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="max-h-60 overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {filteredData?.length === 0 && (
                <li className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">
                  <span className="block truncate">{t('general.common.noResultsFound')}</span>
                </li>
              )}
              {filteredData?.map(item => {
                return (
                  <ComboboxOption
                    key={`cb-${randId()}`}
                    badge={item.badge}
                    label={item.label}
                    customLabel={item.customLabel}
                    selected={item === selectedOption}
                    onSelect={() => {
                      setOpen(false);
                      if (item !== selectedOption) onSelectionChange && onSelectionChange(item.item);
                    }}
                  />
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export const ComboboxOption = ({
  badge,
  label,
  onSelect,
  selected,
  customLabel
}: {
  badge?: string;
  label?: string;
  onSelect: () => void;
  selected?: boolean;
  customLabel?: ReactNode;
}) => {
  return (
    <li
      className="group relative flex cursor-pointer select-none items-center justify-between py-2 pl-8 pr-4 text-gray-900 hover:bg-indigo-600 hover:text-white"
      onMouseDown={onSelect}
    >
      <div>
        <span className={classNames('block truncate', selected ? 'font-semibold' : 'font-normal')}>
          {customLabel || label}
        </span>
        <span className={classNames('absolute inset-y-0 left-0 flex items-center pl-1.5')}>
          {selected && <CheckImage className="h-5 w-5" />}
        </span>
      </div>
      {badge && (
        <span className="ml-1 inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
          {badge}
        </span>
      )}
    </li>
  );
};
