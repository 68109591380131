import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { useUpgradeSubscriptionPlan } from '@src/hooks';
import { Subscription, SubscriptionIdentifier } from '@src/models';

import { Alert, Button, ErrorAlert, WideSlideover } from '../common';

import { SubscriptionPackage } from './SubscriptionPackage';

type SubscriptionExistingPlanUpgradeProps = {
  visible: boolean;
  onClose: () => void;
  subscription: Subscription;
};

export const SubscriptionExistingPlanUpgrade = ({
  visible,
  onClose,
  subscription
}: SubscriptionExistingPlanUpgradeProps) => {
  const { t } = useTranslation();
  const [selectedPackage, setSelectedPackage] = useState<SubscriptionIdentifier | undefined>(undefined);
  const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);
  const showYearlyPackages =
    subscription.subscriptionIdentifier &&
    [
      SubscriptionIdentifier.STARTER_YEARLY,
      SubscriptionIdentifier.TEAM_YEARLY,
      SubscriptionIdentifier.PRO_YEARLY
    ].includes(subscription.subscriptionIdentifier);

  const { isLoading: upgradeStarted, mutateAsync: upgradePlan, error } = useUpgradeSubscriptionPlan();

  // reset state and close
  const resetAndClose = () => {
    setSelectedPackage(undefined);
    setUpgradeSuccess(false);
    onClose();
  };

  const onUpgrade = (selectedPackage: SubscriptionIdentifier) => {
    if (selectedPackage) {
      upgradePlan({ subscriptionIdentifier: selectedPackage }).then(response => {
        if (!response) return;
        setSelectedPackage(undefined);
        setUpgradeSuccess(true);
      });
    }
  };

  return (
    <WideSlideover
      visible={visible}
      onClose={resetAndClose}
      title={t('components.user.SubscriptionPlanUpgrade.title')}
      subtitle={t('components.user.SubscriptionExistingPlanUpgrade.subtitle')}
    >
      <>
        {visible && <div className="checkout-container" />}
        <div className="space-y-6 overflow-y-scroll px-4 py-5 sm:p-6">
          {!upgradeSuccess && !selectedPackage && (
            <>
              {!showYearlyPackages && (
                <>
                  {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER && (
                    <SubscriptionPackage
                      name="Starter"
                      price={59}
                      packageMinutes={25}
                      packageStorage={1}
                      packageRetention={24}
                      packageHighPro={false}
                      packageVideoGeniusVideos={5}
                      onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.STARTER)}
                      selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER}
                    />
                  )}
                  {subscription?.subscriptionIdentifier !== SubscriptionIdentifier.PRO && (
                    <SubscriptionPackage
                      name="Team"
                      price={249}
                      packageMinutes={125}
                      packageStorage={5}
                      packageRetention={48}
                      packageHighPro={true}
                      packageVideoGeniusVideos={5}
                      onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.TEAM)}
                      selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM}
                    />
                  )}
                  <SubscriptionPackage
                    name="Pro"
                    price={599}
                    packageMinutes={325}
                    packageStorage={15}
                    packageRetention={72}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.PRO)}
                    selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO}
                  />
                </>
              )}
              {showYearlyPackages && (
                <>
                  {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_YEARLY && (
                    <SubscriptionPackage
                      name="Starter"
                      yearly
                      price={496}
                      packageMinutes={25}
                      packageStorage={1}
                      packageRetention={24}
                      packageHighPro={false}
                      packageVideoGeniusVideos={5}
                      onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.STARTER_YEARLY)}
                      selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_YEARLY}
                    />
                  )}
                  {subscription?.subscriptionIdentifier !== SubscriptionIdentifier.PRO_YEARLY && (
                    <SubscriptionPackage
                      name="Team"
                      yearly
                      price={2090}
                      packageMinutes={125}
                      packageStorage={5}
                      packageRetention={48}
                      packageHighPro={true}
                      packageVideoGeniusVideos={5}
                      onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.TEAM_YEARLY)}
                      selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM_YEARLY}
                    />
                  )}
                  <SubscriptionPackage
                    name="Pro"
                    yearly
                    price={5030}
                    packageMinutes={325}
                    packageStorage={15}
                    packageRetention={72}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={() => setSelectedPackage(SubscriptionIdentifier.PRO_YEARLY)}
                    selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO_YEARLY}
                  />
                </>
              )}
            </>
          )}
          {selectedPackage && (
            <ConfirmSelectedPackage
              selectedPackage={selectedPackage}
              onUpgrade={() => onUpgrade(selectedPackage)}
              handleBack={() => setSelectedPackage(undefined)}
              upgradeStarted={upgradeStarted}
            />
          )}
          {selectedPackage && !!error && <ErrorAlert error={error} closable={true} />}
          {upgradeSuccess && (
            <Alert
              type="success"
              show={true}
              alertContent={t('components.user.SubscriptionPlanUpgrade.upgradeSuccess')}
            />
          )}
        </div>
      </>
    </WideSlideover>
  );
};

type ConfirmSelectedPackageProps = {
  selectedPackage: SubscriptionIdentifier;
  onUpgrade?: () => void;
  handleBack: () => void;
  upgradeStarted: boolean;
};

const ConfirmSelectedPackage = ({
  selectedPackage,
  onUpgrade,
  handleBack,
  upgradeStarted
}: ConfirmSelectedPackageProps) => {
  const { t } = useTranslation();
  const getPrice = () => {
    switch (selectedPackage) {
      case SubscriptionIdentifier.STARTER:
        return '59';
      case SubscriptionIdentifier.TEAM:
        return '249';
      case SubscriptionIdentifier.PRO:
        return '599';
    }
  };

  return (
    <div className="bg-white sm:rounded-lg">
      <div>
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.title')}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.subtitle')}</p>
            </div>
          </div>
        </div>
        <div>
          <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term1')}
              </span>
            </li>
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term2', { price: getPrice() })}
              </span>
            </li>
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term3')}
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-5 flex justify-end">
          <Button secondary onClick={handleBack}>
            {t('general.action.back')}
          </Button>
          <Button className="ml-2" onClick={onUpgrade} loading={upgradeStarted} disabled={upgradeStarted}>
            {t('general.action.upgrade')}
          </Button>
        </div>
      </div>
    </div>
  );
};
