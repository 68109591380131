import { useTranslation } from 'react-i18next';

import { AfterEffectsVersion } from '@src/models';

import { PlainlyCombobox } from '../common';

export type AEVersionComboboxProps = {
  versions: AfterEffectsVersion[];
  onChange: (version: AfterEffectsVersion) => void;
  notSelectedLabel?: string;
  value?: AfterEffectsVersion;
  disabled?: boolean;
};

export const AEVersionCombobox = ({
  versions,
  onChange,
  notSelectedLabel,
  value,
  disabled
}: AEVersionComboboxProps) => {
  const { t } = useTranslation();

  return (
    <PlainlyCombobox
      className="mt-1"
      notSelectedLabel={notSelectedLabel}
      data={versions.map(version => ({
        name: version,
        item: version,
        label: t('components.project.common.aeVersion', { context: version }),
        selected: version === value
      }))}
      disabled={disabled}
      clearDisabled={true}
      onSelectionChange={state => state && onChange(state)}
    />
  );
};
