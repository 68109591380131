import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { WideSlideover } from '@src/components';
import { SubscriptionIdentifier } from '@src/models';
import { State, useGlobalState } from '@src/state/store';

import { SubscriptionPackage } from './SubscriptionPackage';

type SubscriptionPlanUpgradeProps = {
  visible: boolean;
  onClose: () => void;
};

const getPaddleSubscriptionId = (s: SubscriptionIdentifier) => {
  switch (s) {
    // monthly ones
    case SubscriptionIdentifier.STARTER:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER);
    case SubscriptionIdentifier.TEAM:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM);
    case SubscriptionIdentifier.PRO:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO);

    // yearly ones
    case SubscriptionIdentifier.STARTER_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER_YEARLY);
    case SubscriptionIdentifier.TEAM_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM_YEARLY);
    case SubscriptionIdentifier.PRO_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO_YEARLY);
  }
};

export const SubscriptionPlanUpgrade = ({ visible, onClose }: SubscriptionPlanUpgradeProps) => {
  const { t } = useTranslation();
  const [user] = useGlobalState(State.USER);
  const [organization] = useGlobalState(State.ORGANIZATION);

  const [upgradeStarted, setUpgradeStarted] = useState(false);
  const [showYearlyPlans, setShowYearlyPlans] = useState(true);

  // reset state and close
  const resetAndClose = () => {
    setUpgradeStarted(false);
    onClose();
  };

  // fires on the upgrade button click
  const onUpgrade = (s: SubscriptionIdentifier) => () => {
    setUpgradeStarted(true);

    const passthrough = {
      userId: user?.id,
      organizationId: organization?.id
    };

    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Checkout.open({
      method: 'inline',
      product: getPaddleSubscriptionId(s),
      email: user?.email,
      passthrough: JSON.stringify(passthrough),
      allowQuantity: false,
      disableLogout: true,
      frameTarget: 'checkout-container',
      frameInitialHeight: 450,
      // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;'
    });
  };

  return (
    <WideSlideover
      visible={visible}
      onClose={resetAndClose}
      title={t('components.user.SubscriptionPlanUpgrade.title')}
      subtitle={t('components.user.SubscriptionPlanUpgrade.subtitle')}
    >
      <>
        {visible && <div className="checkout-container" />}
        <div className="space-y-6 overflow-y-scroll px-4 py-5 sm:p-6">
          {!upgradeStarted && (
            <>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    {t('general.action.selectATab')}
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    value={showYearlyPlans ? 'Y' : 'M'}
                    onChange={e => setShowYearlyPlans(e.target.value === 'Y')}
                  >
                    <option key={'M'}>{t('components.user.SubscriptionPlanUpgrade.monthlyPlans')}</option>
                    <option key={'Y'}>{t('components.user.SubscriptionPlanUpgrade.yearlyPlans')}</option>
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      <button
                        onClick={() => setShowYearlyPlans(false)}
                        className={classNames(
                          !showYearlyPlans
                            ? 'border-indigo-500 text-indigo-600'
                            : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium'
                        )}
                        aria-current={!showYearlyPlans ? 'page' : undefined}
                      >
                        {t('components.user.SubscriptionPlanUpgrade.monthlyPlans')}
                      </button>
                      <button
                        onClick={() => setShowYearlyPlans(true)}
                        className={classNames(
                          showYearlyPlans
                            ? 'border-indigo-500 text-indigo-600'
                            : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium'
                        )}
                        aria-current={showYearlyPlans ? 'page' : undefined}
                      >
                        <span>{t('components.user.SubscriptionPlanUpgrade.yearlyPlans')}</span>
                        <span className="ml-1 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                          {t('components.user.SubscriptionPlanUpgrade.yearlyDiscount')}
                        </span>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
              {!showYearlyPlans && (
                <>
                  <SubscriptionPackage
                    name="Starter"
                    price={59}
                    packageMinutes={25}
                    packageStorage={1}
                    packageRetention={24}
                    packageHighPro={false}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.STARTER)}
                  />
                  <SubscriptionPackage
                    name="Team"
                    price={249}
                    packageMinutes={125}
                    packageStorage={5}
                    packageRetention={48}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.TEAM)}
                  />
                  <SubscriptionPackage
                    name="Pro"
                    price={599}
                    packageMinutes={325}
                    packageStorage={15}
                    packageRetention={72}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.PRO)}
                  />
                </>
              )}
              {showYearlyPlans && (
                <>
                  <SubscriptionPackage
                    name="Starter"
                    yearly
                    price={496}
                    packageMinutes={25}
                    packageStorage={1}
                    packageRetention={24}
                    packageHighPro={false}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.STARTER_YEARLY)}
                  />
                  <SubscriptionPackage
                    name="Team"
                    yearly
                    price={2090}
                    packageMinutes={125}
                    packageStorage={5}
                    packageRetention={48}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.TEAM_YEARLY)}
                  />
                  <SubscriptionPackage
                    name="Pro"
                    yearly
                    price={5030}
                    packageMinutes={325}
                    packageStorage={15}
                    packageRetention={72}
                    packageHighPro={true}
                    packageVideoGeniusVideos={5}
                    onUpgrade={onUpgrade(SubscriptionIdentifier.PRO_YEARLY)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </>
    </WideSlideover>
  );
};
