import { Trans, useTranslation } from 'react-i18next';

import { SideInfoHeader } from '@src/components';
import { OutputFormat, OutputFormatType, PostEncode } from '@src/models';

export const RenderOutputFormatInfo = ({ outputFormat }: { outputFormat: OutputFormat }) => {
  const { t } = useTranslation();

  const postEncodingType = outputFormat?.postEncoding?.type;
  const encodingFormat = postEncodingType === 'custom' ? outputFormat?.postEncoding?.encodingFormat : undefined;
  const encodingParamsLine = postEncodingType === 'custom' ? outputFormat?.postEncoding?.encodingParamsLine : undefined;

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <SideInfoHeader title={t('components.render.common.outputFormat')} />
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 text-sm">
          <div>
            <dt className="font-medium text-gray-500">{t('components.render.common.fileFormat')}</dt>
            <dd className="mt-1 break-all text-gray-900">
              <Trans
                i18nKey={'components.render.common.fileFormatValue'}
                tOptions={{ contentType: outputFormat.contentType, ext: outputFormat.ext }}
              >
                {outputFormat.contentType} (.{outputFormat.ext})
              </Trans>
            </dd>
          </div>
          {outputFormat.settingsTemplate && (
            <div>
              <dt className="font-medium text-gray-500">{t('components.render.common.settingsTemplate')}</dt>
              <dd className="mt-1 break-all text-gray-900">
                {t('components.render.common.settingsTemplate', { context: outputFormat.settingsTemplate })}
              </dd>
            </div>
          )}
          {outputFormat.outputModule && (
            <div>
              <dt className="font-medium text-gray-500">{t('components.render.common.outputModule')}</dt>
              <dd className="mt-1 break-all text-gray-900">
                {t('components.render.common.outputModule', { context: outputFormat.outputModule })}
              </dd>
            </div>
          )}

          {!outputFormat.postEncoding && outputFormat.encodingNeeded && (
            <div>
              <dt className="font-medium text-gray-500">{t('components.render.common.postEncodingType')}</dt>
              <dd className="mt-1 break-all text-gray-900">
                {t('components.render.common.postEncodingType', { context: 'default' })}
              </dd>
            </div>
          )}
          {outputFormat.postEncoding && (
            <>
              {postEncodingType !== 'captions' && (
                <div>
                  <dt className="font-medium text-gray-500">{t('components.render.common.postEncodingType')}</dt>
                  <dd className="mt-1 break-all text-gray-900">
                    {t('components.render.common.postEncodingType', { context: outputFormat.postEncoding.type })}
                  </dd>
                </div>
              )}

              {postEncodingType === 'captions' && (
                <CaptionsPostEncoding baseEncoding={outputFormat.postEncoding.baseEncoding} />
              )}

              <CustomPostEncoding
                postEncodingType={postEncodingType}
                encodingFormat={encodingFormat}
                encodingParamsLine={encodingParamsLine}
              />
            </>
          )}
        </dl>
      </div>
    </div>
  );
};

const CustomPostEncoding = ({
  postEncodingType,
  encodingFormat,
  encodingParamsLine
}: {
  postEncodingType: string | undefined;
  encodingFormat: OutputFormatType | undefined;
  encodingParamsLine: string | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {postEncodingType === 'custom' && (
        <div>
          <dt className="font-medium text-gray-500">{t('components.render.common.customPostEncoding')}</dt>
          {encodingFormat && encodingParamsLine && (
            <dd className="mt-1 break-all text-gray-900">
              <Trans
                i18nKey={'components.render.common.customPostEncoding'}
                context="both"
                tOptions={{
                  encodingFormat: t('components.render.RenderForm.outputFormat', { context: encodingFormat }),
                  encodingParamsLine
                }}
              >
                {encodingFormat} with encoding params
                <span className="prose prose-sm">
                  <code>{encodingParamsLine}</code>
                </span>
              </Trans>
            </dd>
          )}

          {encodingFormat && !encodingParamsLine && (
            <dd className="mt-1 break-all text-gray-900">
              {t('components.render.RenderForm.outputFormat', { context: encodingFormat })}
            </dd>
          )}

          {!encodingFormat && encodingParamsLine && (
            <dd className="mt-1 text-gray-900">
              <Trans
                i18nKey={'components.render.common.customPostEncoding'}
                context="params"
                tOptions={{ encodingFormat, encodingParamsLine }}
              >
                Encoding params
                <span className="prose prose-sm ml-1">
                  <code>{encodingParamsLine}</code>
                </span>
              </Trans>
            </dd>
          )}
        </div>
      )}
    </>
  );
};

const CaptionsPostEncoding = ({ baseEncoding }: { baseEncoding: PostEncode | undefined }) => {
  const { t } = useTranslation();

  const baseEncodingType = baseEncoding?.type;
  const baseEncodingFormat = baseEncodingType === 'custom' ? baseEncoding?.encodingFormat : undefined;
  const baseEncodingParamsLine = baseEncodingType === 'custom' ? baseEncoding?.encodingParamsLine : undefined;

  return (
    <>
      <div>
        <dt className="font-medium text-gray-500">{t('components.render.common.postEncodingType')}</dt>
        <dd className="mt-1 break-all text-gray-900">
          {t('components.render.RenderOutputFormatInfo.captions', {
            context: baseEncodingType
          })}
        </dd>
      </div>
      <CustomPostEncoding
        postEncodingType={baseEncodingType}
        encodingFormat={baseEncodingFormat}
        encodingParamsLine={baseEncodingParamsLine}
      />
    </>
  );
};
