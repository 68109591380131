import { useTranslation } from 'react-i18next';

import { SignalIcon as RenderingIcon } from '@heroicons/react/24/outline';
import { Button } from '@src/components/common';
import { useFireRenderWebhookRetry, useNotifications } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Render, WebhookDeliveryStatus } from '@src/models';
import { PropsWithModel } from '@src/types';
import { tryFormatJSON } from '@src/utils';

import { SideInfoHeader } from './SideInfoHeader';

type RenderModelProps = PropsWithModel<Render> & {
  canRetrigger: boolean | null | undefined;
};

export const RenderWebhookInfo = ({ model: render, canRetrigger }: RenderModelProps) => {
  const { t } = useTranslation();
  const { webhook, webhookDelivery, id: renderId } = render;
  const { notifyInfo } = useNotifications();

  const deliveries = webhookDelivery.deliveries;
  const latestDelivery = deliveries.length > 0 ? deliveries.reduce((a, b) => (a.date > b.date ? a : b)) : null;

  const { isLoading, mutateAsync: retriggerWebhook } = useFireRenderWebhookRetry();

  const retrigger = () => {
    retriggerWebhook(renderId).then(() => {
      notifyInfo(t('components.render.RenderDetails.reTriggerWebhookSuccess'));
    });
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <SideInfoHeader
        title={t('components.render.common.webhook')}
        action={
          <div className="ml-4 mt-2 shrink-0">
            <Button onClick={retrigger} loading={isLoading} disabled={isLoading || !canRetrigger} secondary={true}>
              {t('components.render.RenderDetails.reTriggerWebhook')}
            </Button>
          </div>
        }
      />
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
          <div>
            <dt className="text-sm font-medium text-gray-500">{t('components.render.RenderDetails.webhookUrl')}</dt>
            <dd className="mt-1 break-all text-sm text-gray-900">{webhook.url}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">{t('components.render.common.passthrough')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {webhook.passthrough ? (
                <p className="mt-2 w-full overflow-auto whitespace-pre rounded-md border border-gray-300 p-2 font-mono text-xs">
                  {tryFormatJSON(webhook.passthrough)}
                </p>
              ) : (
                t('components.render.common.noPassthrough')
              )}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">{t('general.common.status')}</dt>
            <dd className="mt-1 flex text-sm text-gray-900">
              {webhookDelivery.status === WebhookDeliveryStatus.RETRYING && (
                <RenderingIcon className="mr-1.5 h-5 w-5 shrink-0 animate-spin text-gray-400" />
              )}
              {t('components.render.RenderDetails.webhookStatus', { context: webhookDelivery.status })}
            </dd>
          </div>
          {webhookDelivery.status !== WebhookDeliveryStatus.PENDING &&
            webhookDelivery.status !== WebhookDeliveryStatus.IGNORED &&
            latestDelivery && (
              <>
                <div>
                  <dt className="text-sm font-medium text-gray-500">
                    {t('components.render.RenderDetails.webhookLastDelivery')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {latestDelivery.success
                      ? t('components.render.RenderDetails.webhookDeliveryInfoSuccess', {
                          responseCode: latestDelivery.responseCode || '',
                          date: localizationHelper.forDate().formatDateTimeStringLocally(latestDelivery.date)
                        })
                      : latestDelivery.responseCode
                        ? t('components.render.RenderDetails.webhookDeliveryInfoFailureWithCode', {
                            responseCode: latestDelivery.responseCode || '',
                            date: localizationHelper.forDate().formatDateTimeStringLocally(latestDelivery.date)
                          })
                        : t('components.render.RenderDetails.webhookDeliveryInfoFailure', {
                            date: localizationHelper.forDate().formatDateTimeStringLocally(latestDelivery.date)
                          })}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">
                    {t('components.render.RenderDetails.webhookTriedDeliveries')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{webhookDelivery.deliveries.length}</dd>
                </div>
              </>
            )}
        </dl>
      </div>
    </div>
  );
};
