import { useTranslation } from 'react-i18next';

import localizationHelper from '@src/i18n';
import { State, useGlobalState } from '@src/state/store';

import { CopyIdAction } from '../common';

export const UserProfileCard = () => {
  const { t } = useTranslation();
  const [user] = useGlobalState(State.USER);

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('components.user.UserProfileCard.title')}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('components.user.UserProfileCard.subtitle')}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.user.common.fullName')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <span>{user?.name}</span>
              {user?.id && <CopyIdAction className="mt-1" id={user.id} type="user" />}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.user.common.nickname')}</dt>
            <dd className="mt-1 text-sm text-gray-900">{user?.nickname}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.email')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <span>{user?.email}</span>
              <span className="text-xs text-gray-500">
                {t('components.user.UserProfileCard.emailVerified', { context: `${user?.emailVerified}` })}
              </span>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.user.UserProfileCard.joined')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateStringLocally(user?.createdDate)}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
