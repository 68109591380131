export type DesignNumberInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onFieldUpdate: (value: number) => void;
};

export const DesignNumberInput = ({
  id,
  name,
  'aria-describedby': ariaDescribedBy,
  step,
  min,
  max,
  value,
  onFieldUpdate
}: DesignNumberInputProps) => {
  return (
    <input
      id={id}
      name={name}
      aria-describedby={ariaDescribedBy}
      type="number"
      inputMode="decimal"
      step={step}
      min={min}
      max={max}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      onChange={e => {
        const value = parseFloat(e.target.value);
        onFieldUpdate(value);
      }}
      value={value}
    />
  );
};
