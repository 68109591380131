import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { generatePath, Link } from 'react-router-dom';

import { ColorPaletteGroup, Design, DesignAspectRatio, DesignExample } from '@src/models';
import * as routes from '@src/routes';

import { Button } from '../common';

export type DesignsVideoPlayerProps = {
  design: Design;
  selectedColors: ColorPaletteGroup[];
  selectedAspectRatios: DesignAspectRatio[];
  singlePreview?: boolean;
};

export type VideoProps = {
  example: DesignExample;
  designId: string;
  singlePreview?: boolean;
};

const Video = ({ example, designId, singlePreview }: VideoProps) => {
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>();
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '200px 0px'
  });

  const { thumbnailUrl, videoUrl } = example;
  const { t } = useTranslation();

  const setRefs = useCallback(
    (node: HTMLVideoElement) => {
      videoRef.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  const playVideo = () => {
    videoRef.current?.play().catch(error => {
      console.warn('Video play failed because:', error);
    });
    setVideoPlaying(true);
  };

  return (
    <>
      <video
        ref={setRefs}
        controls={videoPlaying}
        onEnded={e => {
          setVideoPlaying(false);
          e.currentTarget.load();
        }}
        poster={thumbnailUrl}
        preload={inView ? 'auto' : 'none'}
      >
        <source src={videoUrl} />
      </video>

      <span
        className={classNames(
          'group flex h-full w-full items-center justify-center',
          !videoPlaying && 'absolute inset-0 cursor-pointer'
        )}
        aria-hidden="true"
        onClick={() => !videoPlaying && playVideo()}
      >
        {!videoPlaying && (
          <svg className="h-12 w-12 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
            <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
          </svg>
        )}
      </span>
      {!singlePreview && (
        <Link
          aria-hidden="true"
          className={classNames('absolute hidden w-full p-2 group-hover:flex', videoPlaying ? 'top-0' : 'bottom-0')}
          to={generatePath(routes.DESIGN_DETAILS_URL, { designId: designId })}
        >
          <Button className="w-full items-center justify-center">{t('general.action.render')}</Button>
        </Link>
      )}
    </>
  );
};

export const DesignsVideoPlayer = ({
  design,
  selectedColors,
  selectedAspectRatios,
  singlePreview
}: DesignsVideoPlayerProps) => {
  const shouldShow = (aspect: DesignAspectRatio, color: ColorPaletteGroup, isDefault: boolean) => {
    const colorsFiltered = selectedColors.length > 0;
    const colorIncluded = selectedColors.includes(color);
    const aspectsFiltered = selectedAspectRatios.length > 0;
    const aspectIncluded = selectedAspectRatios.includes(aspect);

    if (!colorsFiltered && !aspectsFiltered) {
      return isDefault;
    } else if (colorsFiltered && aspectsFiltered) {
      return colorIncluded && aspectIncluded;
    } else if (colorsFiltered) {
      return colorIncluded;
    } else if (aspectsFiltered) {
      return aspectIncluded;
    }
    return false;
  };

  let exampleShown = false;
  return (
    <div className="relative mx-auto w-full overflow-hidden bg-gray-200 group-hover:opacity-75">
      {design.variants.map(variant => {
        return Object.entries(variant.examples).map((entry, index) => {
          // if already have example show don't display anything
          if (exampleShown) {
            return null;
          }

          // otherwise, check if it should be shown
          const color = entry[0] as ColorPaletteGroup;
          const example = entry[1];
          const isDefault = design.defaultExample.videoUrl === example.videoUrl;
          exampleShown = shouldShow(variant.aspectRatio, color, isDefault);
          return (
            <div key={index}>
              {exampleShown && <Video example={example} designId={design.id} singlePreview={singlePreview} />}
            </div>
          );
        });
      })}
    </div>
  );
};
