import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { RectangleStackIcon } from '@heroicons/react/24/solid';
import { AEVersionCombobox, Button, StyledA } from '@src/components';
import { useEditProject, useNotifications, useUploadProject } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { AfterEffectsVersion, Project } from '@src/models';
import * as routes from '@src/routes';
import { toMb } from '@src/utils';

export type ProjectCreateFormProps = {
  project?: Project;
};

// max 300MB upload
const MAX_FILE_SIZE = 300 * 1024 * 1024;
const allowedAeVersions = [AfterEffectsVersion.AE2022, AfterEffectsVersion.AE2023_AMD64, AfterEffectsVersion.AE2024];

export const ProjectCreateForm = ({ project }: ProjectCreateFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notifyWarn } = useNotifications();

  const [inputs, setInputs] = useState<{
    file?: File;
    projectName?: string;
    projectDescription?: string;
    tags?: string[];
    aeVersion?: AfterEffectsVersion;
  }>({
    file: undefined,
    projectName: project?.name || '',
    projectDescription: project?.description,
    tags: project?.attributes?.tags || [],
    aeVersion:
      project?.aeVersion && allowedAeVersions.includes(project.aeVersion)
        ? project.aeVersion
        : AfterEffectsVersion.AE2024
  });

  const editMode = project !== undefined;

  const { isLoading: isLoadingUpload, mutateAsync: uploadProject } = useUploadProject();
  const { isLoading: isLoadingEdit, mutateAsync: editProject } = useEditProject();

  const isLoading = isLoadingUpload || isLoadingEdit;

  // handles the updates to the file list
  const handleFileList = (files: FileList | null) => {
    const file = files && files[0];
    if (file) {
      if (file.size >= MAX_FILE_SIZE) {
        notifyWarn(t('components.project.ProjectCreateForm.overMaxSize'));
      } else {
        setInputs({ ...inputs, file });
      }
    }
  };

  // fire the upload
  const canSaveEdits =
    (inputs.file ||
      project?.name !== inputs.projectName ||
      project?.description !== inputs.projectDescription ||
      project?.attributes?.tags !== inputs.tags) &&
    !isLoading;
  const canFireUpload = (editMode || inputs.file !== undefined) && !isLoading;
  const fireUpload = async () => {
    let newProject = null;

    if (editMode) {
      newProject = await editProject({
        projectId: project.id,
        project: {
          name: inputs.projectName,
          description: inputs.projectDescription,
          file: inputs.file,
          aeVersion: inputs.aeVersion,
          tags: inputs.tags?.map(tag => tag.trim())
        }
      });
    } else {
      if (inputs.file) {
        newProject = await uploadProject({
          name: inputs.projectName,
          description: inputs.projectDescription,
          file: inputs.file,
          aeVersion: inputs.aeVersion,
          tags: inputs.tags?.map(tag => tag.trim())
        });
      }
    }

    if (newProject) {
      navigate(generatePath(routes.PROJECT_DETAILS, { id: newProject.id }));
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {editMode
                ? t('components.project.ProjectCreateForm.editMode.title')
                : t('components.project.ProjectCreateForm.title')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {editMode
                ? t('components.project.ProjectCreateForm.editMode.description')
                : t('components.project.ProjectCreateForm.description')}
              <Trans i18nKey="components.project.ProjectCreateForm.uploadGuidelines">
                {'We expect a ZIP file containing your complete project. Please read the '}
                <StyledA
                  href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#projects`}
                  target="_blank"
                >
                  following guidelines
                </StyledA>
                {' in order to learn how to prepare your project files for the upload.'}
              </Trans>
            </p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                fireUpload();
              }}
            >
              <div>
                <div>
                  <label htmlFor="project-name" className="block text-sm font-medium text-gray-700">
                    {t('general.common.name')}
                  </label>
                  <input
                    type="text"
                    name="project-name"
                    id="project-name"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, projectName: e.target.value.trim() })}
                    defaultValue={inputs.projectName}
                  />
                </div>
                <div>
                  <label htmlFor="description" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('general.common.description')}
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, projectDescription: e.target.value })}
                    defaultValue={inputs.projectDescription}
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="tags" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.project.common.tags')}
                  </label>
                  <input
                    type="text"
                    name="tags"
                    id="tags"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, tags: e.target.value.split(',') })}
                    defaultValue={inputs.tags}
                    placeholder="Example: Sports, Fitness, Gym"
                  />
                </div>
                <div>
                  <label htmlFor="aeVersion" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.project.common.aeVersion')}
                  </label>
                  <AEVersionCombobox
                    versions={allowedAeVersions}
                    onChange={aeVersion => setInputs({ ...inputs, aeVersion })}
                    value={inputs.aeVersion}
                    disabled={editMode}
                  />
                </div>
                <div>
                  <label className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.project.ProjectCreateForm.projectFiles')}
                  </label>
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                    <div className="space-y-1 text-center">
                      <RectangleStackIcon className="mx-auto h-12 w-12 text-gray-400" />
                      <div className="flex place-content-center text-sm text-gray-600">
                        {inputs.file && <p className="pr-1">{inputs.file.name}</p>}
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>
                            {(!inputs.file && t('general.action.selectFile')) || t('general.action.changeFile')}
                          </span>
                          <input
                            id="file-upload"
                            accept=".zip"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            required={!editMode}
                            onChange={event => handleFileList(event?.target?.files)}
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">
                        {inputs.file
                          ? t('components.project.common.fileSize', {
                              size: localizationHelper
                                .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                                .format(toMb(inputs.file.size))
                            })
                          : t('components.project.ProjectCreateForm.maxFileSize')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {navigate.length > 0 && (
          <Button secondary={true} onClick={() => navigate(-1)}>
            {t('general.action.back')}
          </Button>
        )}
        <Button
          className="ml-3"
          type="submit"
          disabled={editMode ? !canSaveEdits : !canFireUpload}
          loading={isLoading}
          onClick={fireUpload}
        >
          {(editMode && t('general.action.update')) || t('general.action.upload')}
        </Button>
      </div>
    </div>
  );
};
