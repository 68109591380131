import { useTranslation } from 'react-i18next';

import { Header } from '@src/components';
import localizationHelper from '@src/i18n';

import { UsageCombobox } from './UsageCombobox';

export type UsageDetailsHeaderProps = {
  startDate?: string;
  endDate?: string;
  resourceType?: string | undefined;
};

export const UsageDetailsHeader = ({ startDate, endDate, resourceType }: UsageDetailsHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Header
      header={t('components.usage.UsageDetails.title')}
      actions={
        <UsageCombobox
          notSelectedLabel={t('components.usage.DailyUsageCombobox.billingPeriodsLabel')}
          resourceType={resourceType}
        />
      }
      subheader={t('components.usage.UsageDetails.periodInfo', {
        start: localizationHelper.forDate().formatDateStringLocally(startDate),
        end: localizationHelper.forDate().formatDateStringLocally(endDate)
      })}
    />
  );
};
