import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Loading, TemplateLayersForm, TemplateNameCompositionForm } from '@src/components';
import { NotFound } from '@src/components/common';
import {
  useEditProjectTemplate,
  useEditTemplateReducer,
  useGetProjectDetails,
  useGetProjectMetadata
} from '@src/hooks';
import { CompositionAeItem, Layer, TemplateDto } from '@src/models';
import * as routes from '@src/routes';

import { getRootComposition } from './utils';

export const TemplateEdit = ({
  projectId,
  templateId,
  step
}: {
  projectId: string;
  templateId: string;
  step?: 'layers';
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // api calls
  const { isLoading: projectLoading, data: project } = useGetProjectDetails(projectId);
  const { isLoading: metaLoading, data: meta } = useGetProjectMetadata(projectId, project?.analyzed || false);
  const { isLoading: saveTemplateLoading, mutateAsync: saveTemplate } = useEditProjectTemplate();

  const loading = projectLoading || metaLoading;
  const templateOriginal = project?.templates.find(t => t.id === templateId);

  const { state, updateTemplate } = useEditTemplateReducer();
  const template = state[templateId] || templateOriginal || {};

  const toLayers = (t: TemplateDto, c: CompositionAeItem) => {
    // keep layers only on matching composition id
    const layers = template.renderingCompositionId === c.id ? template.layers : [];
    const updated = {
      ...t,
      renderingCompositionId: c.id,
      layers
    };

    updateTemplate(templateId, updated);
    const path = generatePath(routes.PROJECT_TEMPLATE_EDIT, { projectId, templateId, step: 'layers' });
    navigate(path);
  };

  const onLayersUpdate = (layers: Partial<Layer>[]) => {
    updateTemplate(templateId, {
      ...template,
      layers
    });
  };

  const onSave = async () => {
    const data = await saveTemplate({ projectId, templateId, template });
    if (data) {
      navigate(generatePath(routes.PROJECT_TEMPLATE_DETAILS, { projectId, templateId }));
    }
  };

  const rootComposition = meta
    ? getRootComposition(meta, template?.renderingCompositionId) ||
      getRootComposition(meta, templateOriginal?.renderingComposition)
    : undefined;

  return (
    <>
      {loading && (
        <div className="h-full w-full place-content-center">
          <Loading title={t('components.project.template.TemplateEdit.loading')} />
        </div>
      )}
      {!loading && (
        <>
          {!(project && templateOriginal && meta) && <NotFound title={t('components.project.template.common.empty')} />}
          {project && templateOriginal && meta && (
            <>
              {step === undefined && (
                <TemplateNameCompositionForm
                  edit={true}
                  template={template}
                  composition={rootComposition}
                  meta={meta}
                  onNext={toLayers}
                />
              )}
              {step === 'layers' && rootComposition && (
                <TemplateLayersForm
                  edit={true}
                  projectId={projectId}
                  template={template}
                  composition={rootComposition}
                  onSave={onSave}
                  onUpdate={onLayersUpdate}
                  saveInProgress={saveTemplateLoading}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
