import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { AnyRevisionUpdateDto, AnyVideoRevision, ArticleVideo, RevisionState } from '@src/models';

import { useAxiosModify, useAxiosRead } from '../useAxios';

const VIDEOS_CACHE_ROOT = 'videos';

export const videosCacheAdd = (client: QueryClient, video: ArticleVideo) => {
  client.setQueryData<ArticleVideo[]>([VIDEOS_CACHE_ROOT, video.brandId, video.articleId], videos =>
    videos ? [...videos, video] : [video]
  );
  client.setQueryData([VIDEOS_CACHE_ROOT, video.brandId, video.articleId, video.id], video);
};

export const videosCacheReplace = (client: QueryClient, video: ArticleVideo) => {
  client.setQueryData<ArticleVideo[]>([VIDEOS_CACHE_ROOT, video.brandId, video.articleId], videos =>
    videos ? videos.map(v => (v.id === video.id ? video : v)) : [video]
  );
  client.setQueryData([VIDEOS_CACHE_ROOT, video.brandId, video.articleId, video.id], video);
};

export const videoRefreshInterval = (video?: ArticleVideo): number | false => {
  if (video) {
    const revisions: AnyVideoRevision[] = video.data.revisions;
    const noRevisions = revisions.length === 0;
    const renderNotDone = revisions.some(revision => revision.state === RevisionState.PROCESSING);
    if (noRevisions || renderNotDone) {
      return 15000;
    }
  }
  return false;
};

export const videosRefreshInterval = (videos?: ArticleVideo[]): number | false => {
  if (videos) {
    const anyShouldRefresh = videos.some(video => videoRefreshInterval(video) !== false);
    if (anyShouldRefresh) {
      return 15000;
    }
  }
  return false;
};

export const useGetArticleVideos = (brandId?: string, articleId?: string) => {
  const { get } = useAxiosRead<ArticleVideo[]>();

  const cacheKey = [VIDEOS_CACHE_ROOT, brandId, articleId];

  const { data, isLoading, error } = useQuery<ArticleVideo[]>(
    cacheKey,
    async () => {
      const response = await get(`/video-genius/brands/${brandId}/articles/${articleId}/videos`);
      return response.data;
    },
    {
      refetchInterval: videosRefreshInterval,
      enabled: !!brandId && !!articleId
    }
  );

  return { data, isLoading, error };
};

export const useGetArticleVideo = (
  brandId: string | undefined,
  articleId: string | undefined,
  videoId: string | undefined,
  enabled = true
) => {
  const { get } = useAxiosRead<ArticleVideo>();

  const cacheKey = [VIDEOS_CACHE_ROOT, brandId, articleId, videoId];

  const { data, isLoading, error } = useQuery<ArticleVideo>(
    cacheKey,
    async () => {
      const response = await get(`/video-genius/brands/${brandId}/articles/${articleId}/videos/${videoId}`);
      return response.data;
    },
    {
      refetchInterval: videoRefreshInterval,
      enabled: !!brandId && !!articleId && !!videoId && enabled
    }
  );

  return {
    data,
    isLoading,
    error
  };
};

type RevisionBaseParams = {
  brandId: string;
  articleId: string;
  videoId: string;
  revisionId: string;
};

export const useCloneArticleVideoRevision = () => {
  const { post } = useAxiosModify<void, ArticleVideo>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ brandId, articleId, videoId, revisionId }: RevisionBaseParams) => {
      const response = await post(
        `video-genius/brands/${brandId}/articles/${articleId}/videos/${videoId}/revisions/${revisionId}/clone`
      );
      return response.data;
    },
    onSuccess: video => {
      videosCacheAdd(queryClient, video);
    }
  });

  return { mutateAsync, isLoading, error };
};

type RevisionUpdateParams = RevisionBaseParams & {
  data: AnyRevisionUpdateDto;
};

export const useUpdateVideoRevision = () => {
  const { post } = useAxiosModify<AnyRevisionUpdateDto, ArticleVideo>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ brandId, articleId, videoId, revisionId, data }: RevisionUpdateParams) => {
      const response = await post(
        `/video-genius/brands/${brandId}/articles/${articleId}/videos/${videoId}/revisions/${revisionId}/save`,
        { ...data }
      );
      return response.data;
    },
    onSuccess: (updated: ArticleVideo) => {
      videosCacheReplace(queryClient, updated);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useSubmitVideoRevision = () => {
  const { post } = useAxiosModify<void, ArticleVideo>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ brandId, articleId, videoId, revisionId }: RevisionBaseParams) => {
      const response = await post(
        `/video-genius/brands/${brandId}/articles/${articleId}/videos/${videoId}/revisions/${revisionId}/submit`
      );
      return response.data;
    },
    onSuccess: (updated: ArticleVideo) => {
      videosCacheReplace(queryClient, updated);
    }
  });

  return { mutateAsync, isLoading, error };
};
