import { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { AeItemType } from '@plainly/types';
import { Alert } from '@src/components/common';
import { useGetProjectMetadata } from '@src/hooks';
import { Layer, Template } from '@src/models';

import { AeItemWithCompositions } from './TemplateLayersForm';
import { collectLayers, getRootComposition } from './utils';

type TemplateCheckMetaProps = {
  projectId: string;
  template: Partial<Template>;
  layer?: Partial<Layer>;
  showAs: 'alert' | 'badge' | 'icon';
  enabled: boolean;
  refetchOnMount?: boolean;
  getMetaChangedResult?: (result: boolean) => void;
};

const matchLayer = (layer: Partial<Layer>, aeItem: AeItemWithCompositions) => {
  // if name or internalId does not match, then it's not the same layer
  if (aeItem.layer.type === AeItemType.COMPOSITION) {
    // if internal id exists check it
    if (layer.internalId && layer.internalId !== aeItem.layer.internalId) {
      return false;
    }

    // proceed with the name check
    const name = aeItem.layer.layerName || aeItem.layer.name;

    if (layer.layerName !== name) {
      return false;
    }
  } else {
    // if internal id exists check it
    if (layer.internalId && layer.internalId !== aeItem.layer.internalId) {
      return false;
    }

    // proceed with the name check
    if (layer.layerName !== aeItem.layer.name) {
      return false;
    }
  }

  // then ensure that all compositions are present
  return layer.compositions?.every(composition => {
    const containsConcatenation = composition?.name.includes('->');
    if (!containsConcatenation) {
      // old style, just check the name of the last one
      return aeItem.compositions.some(c => c[c.length - 1].name === composition.name);
    } else {
      return aeItem.compositions.some(c => c.map(cc => cc.name).join('->') === composition.name);
    }
  });
};

export const TemplateCheckMeta = ({
  projectId,
  template,
  layer,
  showAs,
  enabled,
  refetchOnMount,
  getMetaChangedResult
}: TemplateCheckMetaProps) => {
  const { t } = useTranslation();
  const { data: projectMeta, isLoading: loadingMeta } = useGetProjectMetadata(projectId, enabled, refetchOnMount, {
    silentNotifications: true
  });
  const disabled = loadingMeta || !enabled;

  const checkParametrization = () => {
    const result: { rootExists: boolean; layersInvalid: string[] } = { rootExists: true, layersInvalid: [] };

    // if we don't have meta yet, it's all good
    if (!projectMeta) {
      return result;
    } else {
      const composition = getRootComposition(projectMeta, template.renderingComposition);
      if (!composition) {
        result.rootExists = false;
      } else {
        const allLayers = collectLayers(composition);

        const handleLayerMatching = (layer: Partial<Layer>) => {
          const matchingLayer = allLayers.find(cl => matchLayer(layer, cl));
          if (!matchingLayer) {
            layer.layerName && result.layersInvalid.push(layer.layerName);
          }
        };

        if (layer) {
          handleLayerMatching(layer);
        } else {
          template.layers?.forEach(layer => {
            handleLayerMatching(layer);
          });
        }
      }
      return result;
    }
  };

  const result = checkParametrization();
  const metaChanged = !result.rootExists || result.layersInvalid.length > 0;

  useEffect(() => {
    if (getMetaChangedResult) {
      getMetaChangedResult(metaChanged);
    }
  }, [getMetaChangedResult, metaChanged]);

  return (
    <>
      {!disabled && (
        <>
          {showAs === 'alert' ? (
            <Alert
              type="warn"
              show={metaChanged}
              alertContent={t('components.project.template.TemplateCheckMeta.metadataChangedAlert', {
                context: result.rootExists.toString(),
                layers: result.layersInvalid.join(', ')
              })}
            />
          ) : (
            <div
              className={classNames('flex', !metaChanged && 'hidden')}
              title={t('components.project.template.TemplateCheckMeta.metadataChangedTooltip', {
                context: result.rootExists.toString()
              })}
            >
              <ExclamationTriangleIcon
                className={classNames('mr-1.5 h-5 w-5 shrink-0 text-yellow-400', showAs === 'icon' && 'cursor-help')}
              />
              {showAs === 'badge' && t('components.project.template.TemplateCheckMeta.metadataChangedWarning')}
            </div>
          )}
        </>
      )}
    </>
  );
};
