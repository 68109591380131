import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  CalendarIcon,
  CheckCircleIcon,
  CircleStackIcon,
  RectangleGroupIcon,
  SignalIcon,
  TagIcon,
  XCircleIcon
} from '@heroicons/react/24/solid';
import localizationHelper from '@src/i18n';
import { Project } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';
import { toMb } from '@src/utils';

export type ProjectListItemProps = PropsWithModel<Project> & {
  onTagClick?: (tag: string) => void;
};

export const ProjectListItem = ({ model, onTagClick }: ProjectListItemProps) => {
  const { t } = useTranslation();

  return (
    <li>
      <Link to={generatePath(routes.PROJECT_DETAILS, { id: model.id })} className="block hover:bg-gray-50">
        <div className="px-4 py-4 sm:px-6">
          <div className="flex justify-between sm:items-center">
            <div className="flex items-center gap-2">
              <p className="truncate text-sm font-medium text-indigo-600">{model.name}</p>
              <div className="hidden items-center gap-2 sm:flex">
                <TagIcon className="h-5 w-5 shrink-0 text-gray-400 sm:hidden" />
                {model.attributes?.tags
                  ?.sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex cursor-pointer items-center whitespace-nowrap rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onTagClick?.(tag);
                      }}
                    >
                      {tag}
                    </span>
                  ))}
              </div>
            </div>
            <div className="ml-2 flex shrink-0">
              <p className="text-xs leading-5 text-gray-500">
                {t('components.project.ProjectListItem.templatesCount', { count: model.templates.length })}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              {model.attributes?.tags && model.attributes?.tags.length > 0 && (
                <div className="mb-2 flex items-center gap-2 sm:hidden">
                  <>
                    <TagIcon className="h-5 w-5 shrink-0 text-gray-400 sm:hidden" />
                    {model.attributes?.tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="inline-flex cursor-pointer items-center whitespace-nowrap rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onTagClick?.(tag);
                        }}
                      >
                        {tag}
                      </span>
                    ))}
                  </>
                </div>
              )}
              <p className="flex items-center text-sm text-gray-500 sm:min-w-24">
                <CircleStackIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                {t('components.project.common.fileSize', {
                  size: localizationHelper
                    .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                    .format(toMb(model.size))
                })}
              </p>
              {model.analysis?.done && (
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0 sm:min-w-32">
                  <CheckCircleIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                  {t('components.project.common.analysis', { context: 'done' })}
                </p>
              )}
              {model.analysis?.pending && (
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0 sm:min-w-32">
                  <SignalIcon className="mr-1.5 h-5 w-5 shrink-0 animate-spin text-gray-400" />
                  {t('components.project.common.analysis', { context: 'pending' })}
                </p>
              )}
              {model.analysis?.failed && (
                <p className="mt-2 flex items-center text-sm text-red-500 sm:ml-6 sm:mt-0 sm:min-w-32">
                  <XCircleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                  {t('components.project.common.analysis', { context: 'failed' })}
                </p>
              )}
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0 sm:min-w-32">
                <RectangleGroupIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                {t('components.project.common.aeVersion', { context: model.aeVersion })}
              </p>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <CalendarIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
              <p>{localizationHelper.forDate().formatDateTimeStringLocally(model.createdDate, 'LLL dd, yyyy')}</p>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};
