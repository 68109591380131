import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '../common';

import { ColumnSelectField } from './ColumnSelectField';

type RenderBatchPassthroughModalProps = {
  visible: boolean;
  closeModal: () => void;
  options: string[];
  submit: (key: string, value: string, isColumnSelect: boolean) => void;
};

export const RenderBatchPassthroughModal = ({
  visible,
  closeModal,
  options,
  submit
}: React.PropsWithChildren<RenderBatchPassthroughModalProps>) => {
  return (
    <Modal visible={visible} close={closeModal}>
      <RenderBatchPassthroughModalContent closeModal={closeModal} options={options} submit={submit} />
    </Modal>
  );
};

const RenderBatchPassthroughModalContent = ({
  closeModal,
  options,
  submit
}: React.PropsWithChildren<Omit<RenderBatchPassthroughModalProps, 'visible'>>) => {
  const { t } = useTranslation();

  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [isColumnSelect, setIsColumnSelect] = useState<boolean>(true);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (key && value) {
      submit(key, value, isColumnSelect);
      closeModal();
    }
  };

  const valueToKey = (value: string) => {
    const noWhiteSpace = value.replace(/\s/g, '');
    return noWhiteSpace[0]?.toLowerCase() + noWhiteSpace?.slice(1);
  };

  useEffect(() => {
    setKey('');
  }, [isColumnSelect]);

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="mx-auto max-w-xl transform rounded-xl transition-all">
        <p className="mb-1 text-sm text-gray-600">{t('components.render.common.value')}</p>
        <ColumnSelectField
          options={options}
          onFieldUpdate={(value, isColumnSelect) => {
            value && isColumnSelect && setKey(valueToKey(value));
            setValue(value);
            setIsColumnSelect(isColumnSelect);
          }}
          value={value}
          columnSelect={isColumnSelect}
        />
        <p className="mb-1 mt-6 text-sm text-gray-600">{t('components.common.parameterName')}</p>
        <input
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
          autoComplete="off"
          type="text"
          onChange={e => setKey(e.target.value)}
          value={key}
          pattern="^[a-zA-Z0-9]+$"
        />
        <p className="mb-1 text-xs text-gray-500">{t('components.render.RenderBatchWebhookModal.keyDescription')}</p>
      </div>
      <div className="flex justify-end pt-4">
        <Button secondary className="mr-3" onClick={() => closeModal()}>
          {t('general.action.cancel')}
        </Button>
        <Button type="submit" disabled={!key || !value}>
          {t('components.render.RenderBatchWebhookModal.add')}
        </Button>
      </div>
    </form>
  );
};
