import classNames from 'classnames';

import { RenderState } from '@src/models';

export const RenderStateCircle = ({ state }: { state: RenderState }) => {
  return (
    <span
      className={classNames(
        'h-2 w-2 rounded-full ring-1 ring-inset',
        state === RenderState.DONE && 'bg-green-500 ring-green-600/10',
        state === RenderState.CANCELLED && 'bg-stone-500 ring-stone-600/10',
        [RenderState.FAILED, RenderState.INVALID].includes(state) && 'bg-red-500 ring-red-600/10',
        [RenderState.PENDING, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(state) &&
          'animate-pulse-tailwind bg-yellow-500 ring-yellow-600/10'
      )}
    />
  );
};
