import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '@heroicons/react/24/solid';
import { AeItemType, AeMediaItemType, MediaAutoScaleTransform } from '@plainly/types';
import { Checkbox, StyledA } from '@src/components/common';
import { DesignColorInput, DesignStringInput } from '@src/components/designs';
import { useValidateUrls } from '@src/hooks';
import { AnyAeItem, Parametrization } from '@src/models';
import { displayToParameterName, ensureColorHex, isValidHexColor } from '@src/utils';

import { EditableAeItemWithCompositions } from '../TemplateLayersForm';

import { MediaLayerInput } from './input';
import { SaveValidation } from './LayerParametrize';

export type LayerParametrizeFormProps = {
  setParamValidation: (validation: SaveValidation) => void;
  identifier: string | undefined;
  setIdentifier: (identifier: string | undefined) => void;
  setParametrization: (parametrization: Parametrization | undefined) => void;
  isActiveTab: boolean;
  editLayer?: EditableAeItemWithCompositions;
  visible: boolean;
  setAutoScaleMedia: (
    autoScale: boolean,
    params: { fill: boolean; fixedRatio: boolean; transform: MediaAutoScaleTransform | undefined }
  ) => void;
  setAutoScaleText: (autoScale: boolean) => void;
  autoScaleMedia: boolean;
  autoScaleText: boolean;
  isEffect: boolean;
};

export const LayerParametrizeForm = ({
  setParamValidation,
  identifier,
  setIdentifier,
  setParametrization,
  isActiveTab,
  editLayer,
  visible,
  autoScaleMedia,
  autoScaleText,
  setAutoScaleMedia,
  setAutoScaleText,
  isEffect
}: LayerParametrizeFormProps) => {
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState<string | undefined>();
  const [staticValue, setStaticValue] = useState<string | undefined>();
  const [staticParam, setStaticParam] = useState<boolean | undefined>();
  const [parameterName, setParameterName] = useState<string | undefined>();
  const [parameterNameInvalid, setParameterNameInvalid] = useState(false);
  const [mandatory, setMandatory] = useState<boolean>(false);

  const staticValueRef = useRef<HTMLInputElement>(null);
  const identifierRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editLayer && editLayer.plainlyLayer?.parametrization) {
      const editParametrization = editLayer.plainlyLayer?.parametrization;
      setStaticParam(!editParametrization?.expression);
      setDefaultValue(editParametrization?.defaultValue);
      setMandatory(editParametrization?.mandatory);
      setIdentifier(editLayer.plainlyLayer?.label);
      !editParametrization?.expression && setStaticValue(editParametrization?.value);
      editParametrization?.expression && setParameterName(editParametrization?.value.substring(1));
    }
  }, [editLayer, setIdentifier]);

  const handleDisplayToParamNameUpdate = useCallback(
    (displayName: string) => {
      const newParam = displayToParameterName(displayName);

      // if they identifier and current parameter name matches, then update always
      if (parameterName === displayToParameterName(identifier || '')) {
        setParameterName(newParam);
      }

      if (displayName && !parameterName) {
        setParameterName(newParam);
      }
    },
    [identifier, parameterName]
  );

  useEffect(() => {
    const isAlreadyParametrized = editLayer?.plainlyLayer?.parametrization;
    if (isAlreadyParametrized) return;

    if (!staticParam && identifier) {
      handleDisplayToParamNameUpdate(identifier);
    }
  }, [editLayer, handleDisplayToParamNameUpdate, identifier, staticParam]);

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const validHex =
    editLayer?.layer.type === AeItemType.MEDIA && editLayer.layer.mediaType === AeMediaItemType.SOLID && staticValue
      ? isValidHexColor(staticValue)
      : true;

  // saving helpers
  const canSaveStatic = staticParam === true && staticValue && urlsValid && validHex;
  const canSaveDynamic =
    staticParam === false && identifier && identifier.length > 0 && parameterName && parameterName.length > 0;
  const hasNoData = staticParam === undefined;

  const onTemplatedValueChange = (target: HTMLInputElement) => setStaticParam(target.value === 'static');

  const mandatoryDisabled = (defaultValue && defaultValue.length > 0) || false;
  const emptyIfUndefined = (value?: string) => value || '';

  const getParametrization = useCallback((): Parametrization => {
    return staticParam
      ? {
          value: staticValue || '',
          expression: false,
          mandatory: false
        }
      : {
          value: `#${parameterName}`,
          expression: true,
          defaultValue,
          mandatory: mandatory
        };
  }, [staticParam, staticValue, parameterName, defaultValue, mandatory]);

  const clearState = useCallback(() => {
    setStaticParam(undefined);
    setStaticValue(undefined);
    setParameterName(undefined);
    setParameterNameInvalid(false);
    setDefaultValue(undefined);
    setMandatory(false);
    setAutoScaleMedia(false, { fill: false, fixedRatio: false, transform: undefined });
    setAutoScaleText(false);
  }, [setAutoScaleMedia, setAutoScaleText]);

  const clearParametrization = () => {
    clearState();
    setParametrization(undefined);
  };

  useEffect(() => {
    if (canSaveStatic || canSaveDynamic) {
      setParamValidation({ hasData: true, isValid: true });
      const p = getParametrization();
      setParametrization(p);
    } else if (hasNoData) {
      setParamValidation({ hasData: false });
    } else {
      setParamValidation({ hasData: true, isValid: false });
    }
  }, [canSaveDynamic, canSaveStatic, getParametrization, hasNoData, setParamValidation, setParametrization]);

  // update the focus of the input on static value change
  useEffect(() => {
    if (staticParam === true) {
      staticValueRef.current?.select();
    } else if (staticParam === false) {
      identifierRef.current?.select();
    }
  }, [staticParam]);

  useEffect(() => {
    if (!visible) {
      clearState();
    }
  }, [clearState, visible]);

  const isImageOrVideo =
    editLayer?.layer?.type === AeItemType.MEDIA &&
    [AeMediaItemType.IMAGE, AeMediaItemType.VIDEO].includes(editLayer.layer.mediaType);

  const autoScaleCheckboxes = (
    <>
      {isImageOrVideo && (
        <AutoScaleCheckbox
          type={editLayer.layer.type}
          checked={autoScaleMedia === true}
          onChange={e => setAutoScaleMedia(e.target.checked, { fill: true, fixedRatio: true, transform: undefined })}
        />
      )}
      {editLayer?.layer?.type === AeItemType.TEXT && (
        <AutoScaleCheckbox
          type={editLayer.layer.type}
          checked={autoScaleText === true}
          onChange={e => setAutoScaleText(e.target.checked)}
        />
      )}
    </>
  );

  const getInputComponent = (layer?: AnyAeItem) => {
    const type = layer?.type;
    const mediaType = type === AeItemType.MEDIA ? layer?.mediaType : undefined;

    switch (type) {
      case AeItemType.TEXT:
        return (
          <DesignStringInput
            id="static_value"
            name="static_value"
            onFieldUpdate={setStaticValue}
            value={emptyIfUndefined(staticValue)}
          />
        );
      case AeItemType.MEDIA:
        if (mediaType !== AeMediaItemType.SOLID) {
          return (
            <MediaLayerInput
              id="static_value"
              name="static_value"
              value={emptyIfUndefined(staticValue)}
              onFieldUpdate={setStaticValue}
              onValidation={layer && handleInvalidUrls(layer.internalId)}
            />
          );
        } else {
          return (
            <DesignColorInput
              id="static_value"
              name="static_value"
              value={emptyIfUndefined(ensureColorHex(staticValue))}
              onFieldUpdate={setStaticValue}
            />
          );
        }
      default:
        return (
          <input
            ref={staticValueRef}
            type="text"
            name="static_value"
            id="static_value"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={emptyIfUndefined(staticValue)}
            onChange={e => setStaticValue(e.target.value)}
          />
        );
    }
  };

  return (
    <>
      {isActiveTab && (
        <>
          <fieldset>
            <div className="mt-2 space-y-5">
              <div className="relative flex items-start">
                <div className="absolute flex h-5 items-center">
                  <input
                    id="templated_static"
                    name="templated_value"
                    value="static"
                    aria-describedby="templated_static_description"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={staticParam === true}
                    onChange={e => onTemplatedValueChange(e.target)}
                  />
                </div>
                <div className="pl-7 text-sm">
                  <label htmlFor="templated_static" className="font-medium text-gray-900">
                    {t('components.project.template.layer.LayerParametrize.staticParam')}
                  </label>
                  <p id="templated_static_description" className="text-gray-500" onClick={() => setStaticParam(true)}>
                    {t('components.project.template.layer.LayerParametrize.staticParamDescription')}
                  </p>
                </div>
              </div>
              <div>
                <div className="relative flex items-start">
                  <div className="absolute flex h-5 items-center">
                    <input
                      id="templated_dynamic"
                      name="templated_value"
                      value="dynamic"
                      aria-describedby="templated_dynamic_description"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      checked={staticParam === false}
                      onChange={e => onTemplatedValueChange(e.target)}
                    />
                  </div>
                  <div className="pl-7 text-sm">
                    <label htmlFor="templated_dynamic" className="font-medium text-gray-900">
                      {t('components.project.template.layer.LayerParametrize.dynamicParam')}
                    </label>
                    <p
                      id="templated_dynamic_description"
                      className="text-gray-500"
                      onClick={() => setStaticParam(false)}
                    >
                      {t('components.project.template.layer.LayerParametrize.dynamicParamDescription')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          {staticParam === true && (
            <div className="space-y-5 pb-6 pt-4">
              <div>
                <label htmlFor="static_value" className="block text-sm font-medium text-gray-900">
                  {t('components.project.template.layer.LayerParametrize.staticValue')}
                </label>
                <div className="mt-1">{getInputComponent(editLayer?.layer)}</div>
              </div>
              {!isEffect && autoScaleCheckboxes}
            </div>
          )}
          {staticParam === false && (
            <div className="space-y-6 pb-6 pt-4">
              <div>
                <label htmlFor="identifier" className="block text-sm font-medium text-gray-900">
                  {t('components.project.template.layer.LayerParametrize.identifier')}
                </label>
                <p id="identifier_description" className="text-sm text-gray-500">
                  {t('components.project.template.layer.LayerParametrize.identifierDescription')}
                </p>
                <div className="mt-1">
                  <input
                    ref={identifierRef}
                    type="text"
                    name="identifier"
                    id="identifier"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={emptyIfUndefined(identifier)}
                    onChange={e => {
                      handleDisplayToParamNameUpdate(e.target.value);
                      setIdentifier(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="parameter_name" className="block text-sm font-medium text-gray-900">
                  {t('components.common.parameterName')}
                </label>
                <p id="parameter_name_description" className="text-sm text-gray-500">
                  {t('components.project.template.layer.LayerParametrize.dynamicValueDescription')}
                </p>
                <div className="mt-1">
                  <input
                    type="text"
                    pattern="[A-Za-z][A-Za-z0-9_\.]*"
                    name="parameter_name"
                    id="parameter_name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={emptyIfUndefined(parameterName)}
                    onChange={e => {
                      setParameterName(e.target.value);
                      setParameterNameInvalid(!e.target.validity.valid);
                    }}
                    onInvalid={() => setParameterNameInvalid(true)}
                  />
                </div>
                {parameterNameInvalid && (
                  <p className="mt-1 text-sm text-red-600" id="parameter_name-error">
                    {t('components.project.template.layer.LayerParametrize.dynamicValueInvalidInput')}
                  </p>
                )}
              </div>
              <div className={classnames(mandatory && 'cursor-not-allowed opacity-50')}>
                <label htmlFor="default_value" className="block text-sm font-medium text-gray-900">
                  {t('components.project.template.layer.LayerParametrize.defaultValue')}
                </label>
                <p id="default_value_description" className="text-sm text-gray-500">
                  {t('components.project.template.layer.LayerParametrize.defaultValueDescription')}
                </p>
                <div className="mt-1">
                  <input
                    disabled={mandatory}
                    type="text"
                    name="default_value"
                    id="default_value"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={emptyIfUndefined(defaultValue)}
                    onChange={e =>
                      e.target.value === '' ? setDefaultValue(undefined) : setDefaultValue(e.target.value)
                    }
                  />
                </div>
              </div>
              <Checkbox
                id={'mandatory'}
                checked={mandatory === true}
                onChange={e => setMandatory(e.target.checked)}
                label={t('components.project.template.common.mandatory')}
                description={t('components.project.template.layer.LayerParametrize.mandatoryDescription')}
                disabled={mandatoryDisabled}
              />
              {!isEffect && autoScaleCheckboxes}
            </div>
          )}
          {staticParam !== undefined && (
            <div>
              <span className="text-sm">
                <StyledA onClick={clearParametrization} className="cursor-pointer" icon={<TrashIcon />}>
                  {t('components.project.template.layer.LayerParametrize.clearParametrization')}
                </StyledA>
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

const AutoScaleCheckbox = ({
  type,
  checked,
  onChange
}: {
  type: AeItemType;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      id={'autoScale'}
      checked={checked}
      onChange={e => onChange(e)}
      label={t('components.project.template.layer.LayerParametrize.autoScale', { context: type })}
      description={t('components.project.template.layer.LayerParametrize.autoScaleDescription', { context: type })}
    />
  );
};
