import React, { PropsWithChildren, ReactElement, useState } from 'react';

import { FeatureGatingModal } from '@src/components';
import { PlainlyPackage, SubscriptionIdentifier } from '@src/models';
import { State, useGlobalState } from '@src/state/store';
import { includesSafe } from '@src/utils';

type FeatureGatingProps = {
  enabledPackages: PlainlyPackage[];
  freeTrialAllowed: boolean;
  message?: string;
  title?: string;
  disabled?: boolean;
};

export const FeatureGating = ({
  children,
  enabledPackages,
  freeTrialAllowed,
  disabled,
  message,
  title
}: PropsWithChildren<FeatureGatingProps>) => {
  const [subscription] = useGlobalState(State.SUBSCRIPTION);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const unlimited = subscription?.resources.unlimited;
  const packageAllowed = includesSafe(enabledPackages, subscription?.plainlyPackage);
  const trialAllowed = subscription?.subscriptionIdentifier === SubscriptionIdentifier.FREE_TRIAL && freeTrialAllowed;

  // can use if:x
  // - feature gating is not enabled
  // - user is postpaid
  // - user has a package/trial that is not disabled
  const canUse = disabled || unlimited || packageAllowed || trialAllowed;

  return (
    <>
      {children &&
        (canUse
          ? children
          : React.Children.map(children, child => {
              const castedChild = child as ReactElement;
              return (
                castedChild &&
                React.cloneElement(
                  castedChild,
                  { ...castedChild.props, onClick: () => setShowMessage(true) },
                  castedChild.props.children
                )
              );
            }))}
      <FeatureGatingModal visible={showMessage} onClose={() => setShowMessage(false)} message={message} title={title} />
    </>
  );
};
