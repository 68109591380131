import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { generatePath, useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  HeaderWithBreadcrumbs,
  Loading,
  LoadingProps,
  ScrollingPage
} from '@src/components';
import { ArticleItem } from '@src/components/publishers/articles/ArticleItem';
import { useGetBrandDetails, useGetInfiniteArticles, useQueryParams } from '@src/hooks';
import * as routes from '@src/routes';

const PAGE_SIZE = 10;

const LoadingBrandAndArticleDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const ArticlesBrandListPage = () => {
  const { t } = useTranslation();
  const { updateQueryParams, searchQuery } = useQueryParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const search = searchQuery.get('search') || '';

  const { brandId } = useParams() as { brandId: string };

  const { isLoading: isLoadingBrand, data: brand } = useGetBrandDetails(brandId);
  const hasEnabledVideoTypes = brand && brand.settings.videoTypes.filter(vt => vt.enabled).length > 0;

  const {
    isLoading: isLoadingArticles,
    data: articles,
    refetch: fetchArticles,
    hasNextPage,
    fetchNextPage,
    isSuccess
  } = useGetInfiniteArticles(brandId, {
    size: PAGE_SIZE
  });

  const isLoading = isLoadingBrand || isLoadingArticles;

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const filteredArticles = search
    ? articles?.pages?.map(page =>
        page.filter(
          article =>
            article.input.title?.toLowerCase().includes(search) ||
            article.input.description?.toLowerCase().includes(search)
        )
      )
    : articles?.pages;

  const content =
    isSuccess &&
    filteredArticles?.map((page, index) => {
      const isLastPage = index === filteredArticles.length - 1;
      return page.map((article, i) => {
        const isLastInLastPage = isLastPage && i === page.length - 1;
        return (
          <div key={i} ref={isLastInLastPage ? ref : undefined} className="relative">
            <ArticleItem article={article} />
          </div>
        );
      });
    });

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.ArticleBrandListPage')}</title>
        </Helmet>
        {isLoading && <LoadingBrandAndArticleDetails title={t('components.article.ArticlesBrandList.loading')} />}
        {!isLoading && brand && (
          <>
            <HeaderWithBreadcrumbs
              breadcrumbs={
                <Breadcrumb>
                  <BreadcrumbItem to={routes.ARTICLES} label={t('general.common.articles')} />
                  <BreadcrumbItem to={window.location.pathname} label={brand.settings.name} />
                </Breadcrumb>
              }
              header={brand.settings.name}
              logo={brand.settings.logoUrl}
              actions={
                <>
                  <Link to={generatePath(routes.BRAND_DETAILS, { id: brandId })}>
                    <Button secondary>{t('components.article.ArticlesList.brandDetails')}</Button>
                  </Link>
                  <Link className="ml-2" to={generatePath(routes.ARTICLE_CREATE, { brandId })}>
                    <Button
                      disabled={!hasEnabledVideoTypes}
                      title={
                        !hasEnabledVideoTypes ? t('components.article.ArticleCreateForm.missingVideoTypes') : undefined
                      }
                    >
                      {t('components.publishers.common.createArticle')}
                    </Button>
                  </Link>
                </>
              }
            />
            <Alert type="info" show alertContent={t('components.publishers.common.info')} />
            <div className="rounded-xl bg-gray-100 p-0 sm:bg-gray-200 sm:p-2">
              <div className="flex-col space-y-2 md:flex">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (searchTerm !== search) {
                      navigate({
                        search: `?${updateQueryParams(window.location, { search: searchTerm.toLowerCase() })}`
                      });
                    }
                  }}
                  className="w-full space-y-2 md:flex md:space-x-2 md:space-y-0"
                >
                  <input
                    type="text"
                    name="search-term"
                    id="search-term"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                    placeholder="Search..."
                    onChange={e => setSearchTerm(e.target.value)}
                    autoFocus
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        setSearchTerm('');
                        navigate({
                          search: `?${updateQueryParams(window.location, { search: '' })}`
                        });
                      }
                    }}
                    value={searchTerm}
                  />
                  <div className="flex">
                    <Button
                      className="mr-2"
                      type="submit"
                      icon={<MagnifyingGlassIcon />}
                      disabled={searchTerm === search}
                    >
                      {t('general.action.search')}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">{content}</div>
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
