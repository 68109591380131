import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Button, StyledA } from '@src/components';
import localizationHelper from '@src/i18n';
import { CompositionAeItem, ProjectMetaData, TemplateDto } from '@src/models';
import { collectCompositions, compositionSorter } from '@src/utils';

export type TemplateNameCompositionFormProps = {
  edit?: boolean;
  template: TemplateDto;
  composition?: CompositionAeItem;
  meta: ProjectMetaData;
  onNext: (template: TemplateDto, rootComposition: CompositionAeItem) => void;
};

const CompositionSelect = (props: { comp: CompositionAeItem; onSelectedChange: () => void; selected?: boolean }) => {
  const { comp, selected, onSelectedChange } = props;
  const { t } = useTranslation();

  return (
    <Transition
      as="ul"
      show={true}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames(
          'relative flex flex-col rounded-tl-md rounded-tr-md border p-4 md:grid md:grid-cols-3 md:pl-4 md:pr-6',
          (selected && 'z-10 border-indigo-200 bg-indigo-50') || 'border-gray-200'
        )}
      >
        <label className="flex cursor-pointer items-center text-sm">
          <input
            checked={selected}
            onChange={onSelectedChange}
            name="composition_name"
            type="radio"
            className="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
            aria-describedby="plan-option-pricing-0 plan-option-limit-0"
          />
          <span className="ml-3 font-medium text-gray-900">{comp.name}</span>
        </label>
        <p
          id="composition-duration"
          className={classNames(
            'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
            (selected && 'text-indigo-700') || 'text-gray-500'
          )}
        >
          <span>
            {t('general.common.duration', {
              duration: localizationHelper.forNumber({ maximumFractionDigits: 1 }).format(comp.duration)
            })}
          </span>
        </p>
        <p
          id="composition-resolution"
          className={classNames(
            'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
            (selected && 'text-indigo-700') || 'text-gray-500'
          )}
        >
          {t('general.common.resolution', { width: comp.width, height: comp.height })}
        </p>
      </div>
    </Transition>
  );
};

export const TemplateNameCompositionForm = ({
  edit,
  template,
  composition,
  meta,
  onNext
}: TemplateNameCompositionFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedComposition, setSelectedComposition] = useState<CompositionAeItem | undefined>(composition);
  const [selectedName, setSelectedName] = useState<string | undefined>(template.name);
  const [level, setLevel] = useState<number>(0);

  const canGoNext = selectedComposition && selectedName && selectedName.length > 0;
  const increaseLevel = useCallback(() => setLevel(prev => prev + 1), []);
  const goNext = useCallback(() => {
    if (canGoNext) {
      const updated = {
        ...template,
        name: selectedName,
        renderingComposition: selectedComposition.name
      };
      onNext(updated, selectedComposition);
    }
  }, [canGoNext, onNext, selectedComposition, selectedName, template]);

  const shownCompositions = useMemo(() => {
    const result: CompositionAeItem[] = [];
    meta.sort(compositionSorter).forEach(rootComposition => {
      collectCompositions(rootComposition, level).forEach(c => {
        if (result.findIndex(existing => existing.id === c.id) < 0) {
          result.push(c);
        }
      });
    });
    return result;
  }, [level, meta]);

  useEffect(() => {
    if (selectedComposition && shownCompositions.findIndex(c => c.id === selectedComposition.id) < 0) {
      increaseLevel();
    }
  }, [increaseLevel, selectedComposition, shownCompositions]);

  return (
    <div className="flex flex-col space-y-6 md:h-full">
      <div className="overflow-y-auto bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.project.template.TemplateNameCompositionForm.title', { context: edit && 'edit' })}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {t('components.project.template.TemplateNameCompositionForm.description', { context: edit && 'edit' })}
            </p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                goNext();
              }}
            >
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="template_name" className="block text-sm font-medium text-gray-700">
                  {t('components.project.template.TemplateNameCompositionForm.templateName')}
                </label>
                <input
                  autoFocus={true}
                  value={selectedName || ''}
                  onChange={e => setSelectedName(e.target.value)}
                  type="text"
                  name="template_name"
                  id="template_name"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="composition" className="block text-sm font-medium text-gray-700">
                  {t('components.project.template.common.renderingComposition')}
                </label>
                <fieldset id="composition" name="composition" className="mt-1">
                  <legend className="sr-only">{t('components.project.template.common.renderingComposition')}</legend>
                  <ul className="relative -space-y-px overflow-y-auto rounded-md bg-white md:max-h-vh-300">
                    {shownCompositions.map(comp => (
                      <CompositionSelect
                        key={comp.id}
                        comp={comp}
                        selected={comp.id === selectedComposition?.id}
                        onSelectedChange={() => setSelectedComposition(comp)}
                      />
                    ))}
                  </ul>
                </fieldset>
                <div className="mr-1 mt-2 flex flex-row justify-end">
                  <StyledA href="#more" className="text-sm" icon={<ChevronDownIcon />} onClick={increaseLevel}>
                    {t('general.action.showMore')}
                  </StyledA>
                </div>
              </div>
              <input type="submit" hidden disabled={!canGoNext} />
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {navigate.length > 0 && (
          <Button secondary={true} onClick={() => navigate(-1)}>
            {t('general.action.back')}
          </Button>
        )}
        <Button className="ml-3" type="submit" disabled={!canGoNext} onClick={goNext}>
          {t('general.action.next')}
        </Button>
      </div>
    </div>
  );
};
