import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DeleteAction } from '@src/components/common';
import { useDeleteArticle } from '@src/hooks';
import * as routes from '@src/routes';

export const ArticleDeleteAction = ({
  brandId,
  articleId,
  confirmation
}: {
  brandId: string;
  articleId: string;
  confirmation: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, mutateAsync: deleteArticle } = useDeleteArticle();

  const doDelete = () => {
    deleteArticle({ brandId, articleId }).then(() => navigate(generatePath(routes.ARTICLES)));
  };

  return (
    <DeleteAction
      loading={isLoading}
      title={t('components.article.ArticleDeleteAction.title')}
      text={t('components.article.ArticleDeleteAction.text')}
      actionLabel={t('components.article.ArticleDeleteAction.action')}
      confirmMatch={confirmation}
      onConfirmedDelete={doDelete}
    />
  );
};
