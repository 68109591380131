import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { ArrowsPointingOutIcon as ResolutionIcon, ClockIcon, FilmIcon } from '@heroicons/react/24/outline';
import { useCloneTemplate, useSetDefaultProjectTemplate } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Project, Template } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

import { TemplateCheckMeta } from './TemplateCheckMeta';

export type TemplateListItemProps = PropsWithModel<Template> & {
  projectId: string;
  isDefaultTemplate: boolean;
  enableCheckMeta: boolean;
};

export const TemplateListItem = ({ model, projectId, isDefaultTemplate, enableCheckMeta }: TemplateListItemProps) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingSetDefault, mutateAsync: setDefaultTemplate } = useSetDefaultProjectTemplate();
  const { isLoading: isLoadingClone, mutateAsync: cloneTemplate } = useCloneTemplate();

  const isLoading = isLoadingSetDefault || isLoadingClone;

  const menuItems: { label: string; action: () => Promise<Project> }[] = [
    {
      label: t('general.action.setDefault'),
      action: () => setDefaultTemplate({ projectId, templateId: model.id })
    },
    {
      label: t('components.project.template.TemplateListItem.cloneTemplate'),
      action: () => cloneTemplate({ projectId, templateId: model.id })
    }
  ];

  return (
    <li>
      <Link
        to={generatePath(routes.PROJECT_TEMPLATE_DETAILS, { projectId, templateId: model.id })}
        className="block hover:bg-gray-50"
      >
        <div className="flex w-full items-center justify-between gap-x-6 px-4 py-4 sm:px-6">
          <div className="flex w-full flex-col justify-between">
            <div className="flex items-center justify-between">
              <p className="truncate text-sm font-medium text-indigo-600">{model.name}</p>
              <div className="ml-2 flex shrink-0">
                <p className="text-xs leading-5 text-gray-500">
                  {t('components.project.template.TemplateListItem.layerCount', { count: model.layers.length })}
                </p>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="w-full sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <FilmIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                  {model.renderingComposition}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0">
                  <ClockIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                  {t('general.common.duration', {
                    duration: localizationHelper.forNumber({ maximumFractionDigits: 1 }).format(model.duration || 0)
                  })}
                </p>
                {model.resolution && (
                  <p className="mt-2 flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0">
                    <ResolutionIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                    {t('general.common.resolution', {
                      width: model.resolution.width,
                      height: model.resolution.height
                    })}
                  </p>
                )}
              </div>
              <div className="mt-1 flex shrink-0 items-end sm:mt-0">
                <span className="text-xs text-gray-500">
                  {isDefaultTemplate && <p> {t('components.project.common.defaultTemplate')} </p>}
                </span>
              </div>
            </div>
            <div className="mt-2 sm:flex">
              <div className="flex items-center text-sm text-gray-500">
                <TemplateCheckMeta projectId={projectId} template={model} showAs="badge" enabled={enableCheckMeta} />
              </div>
            </div>
          </div>
          <Menu as="div" className="relative flex-none opacity-100">
            <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
              <span className="sr-only">{t('general.action.openMenu')}</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                className={classNames(
                  'absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none',
                  isLoading && 'animate-pulse-tailwind'
                )}
              >
                {menuItems.map((item, index) => (
                  <MenuItem key={index}>
                    {({ focus, close }) => (
                      <button
                        disabled={isLoading}
                        onClick={async e => {
                          e.preventDefault();
                          try {
                            await item.action();
                          } finally {
                            close();
                          }
                        }}
                        className={classNames(
                          focus ? 'bg-gray-50' : '',
                          'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 disabled:cursor-not-allowed'
                        )}
                      >
                        {item.label}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </Link>
    </li>
  );
};
