export type DesignBooleanInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onFieldUpdate: (value: boolean) => void;
};

export const DesignBooleanInput = ({ onFieldUpdate, checked, ...rest }: DesignBooleanInputProps) => {
  return (
    <div className="mr-3 flex h-5 items-center">
      <input
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        checked={checked}
        onChange={e => onFieldUpdate(e.target.checked)}
        {...rest}
      />
    </div>
  );
};
