import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  ArticlesTimeline,
  BrandDeleteAction,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CopyIdAction,
  HeaderWithBreadcrumbs,
  Loading,
  LoadingProps,
  NotFound,
  VideoTypeCreateModal,
  VideoTypesList
} from '@src/components';
import { useGetBrandDetails } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Brand } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

const LoadingBrandDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

type BrandInformationProps = PropsWithModel<Brand>;

const BrandInformation = ({ model: brand }: BrandInformationProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="brand-information-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.brand.BrandDetails.brandInformation')}
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.name')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <span>{brand.settings.name}</span>
              <CopyIdAction id={brand.id} type="brand" className="mt-1" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.brand.BrandDetails.baseUrl')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-indigo-600">
              <a href={brand.settings.baseUrl} target="_blank" rel="noreferrer" className="truncate">
                {brand.settings.baseUrl}
              </a>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.createdOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(brand.createdDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.lastModifiedOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(brand.lastModified)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.logo')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-indigo-600">
              <a href={brand.settings.logoUrl} target="_blank" rel="noreferrer" className="truncate">
                {brand.settings.logoUrl}
              </a>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.language')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">{brand.settings.language}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export const BrandDetails = ({ brandId }: { brandId: string }) => {
  const { t } = useTranslation();

  const { isLoading, data: brand } = useGetBrandDetails(brandId);
  const hasEnabledVideoTypes = brand && brand.settings.videoTypes.filter(vt => vt.enabled).length > 0;

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      {isLoading && <LoadingBrandDetails title={t('components.brand.BrandDetails.loading')} />}
      {!isLoading && (
        <>
          {!brand && <NotFound title={t('components.brand.BrandDetails.empty')} />}
          {brand && (
            <div className="space-y-6">
              <HeaderWithBreadcrumbs
                breadcrumbs={
                  <Breadcrumb>
                    <BreadcrumbItem to={routes.BRANDS} label={t('general.common.brands')} />
                    <BreadcrumbItem to={window.location.pathname} label={brand.settings.name} />
                  </Breadcrumb>
                }
                header={brand.settings.name}
                logo={brand.settings.logoUrl}
                actions={
                  <>
                    <Link to={generatePath(routes.BRAND_EDIT, { id: brandId })}>
                      <Button secondary>{t('general.action.edit')}</Button>
                    </Link>
                    <Link className="ml-2" to={generatePath(routes.ARTICLE_CREATE, { brandId })}>
                      <Button
                        disabled={!hasEnabledVideoTypes}
                        title={
                          !hasEnabledVideoTypes
                            ? t('components.article.ArticleCreateForm.missingVideoTypes')
                            : undefined
                        }
                      >
                        {t('components.publishers.common.createArticle')}
                      </Button>
                    </Link>
                  </>
                }
              />
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                <div className="space-y-6 xl:col-span-2 xl:col-start-1">
                  <section aria-labelledby="brand-information-title">
                    <BrandInformation model={brand} />
                  </section>
                  <section aria-labelledby="brand-video-types-title">
                    <VideoTypesList model={brand} loading={isLoading} setShowModal={setShowModal} />
                  </section>
                  <div className="space-y-6 xl:col-span-2 xl:col-start-1">
                    <BrandDeleteAction brandId={brandId} brandName={brand.settings.name} />
                  </div>
                </div>
                <section aria-labelledby="brand-video-title" className="xl:col-span-1 xl:col-start-3">
                  <ArticlesTimeline brandId={brandId} maxItems={4} />
                </section>
              </div>
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3"></div>
            </div>
          )}
        </>
      )}
      {brand && <VideoTypeCreateModal showModal={showModal} setShowModal={setShowModal} brand={brand} />}
    </>
  );
};
