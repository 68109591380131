import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { AeItemType } from '@plainly/types';
import { CompositionIdentifier } from '@src/models';

type LayerComboboxProps = {
  label: string;
  description: string;
  input?: string;
  value?: string;
  onChange: (e: string) => void;
  compositions?: CompositionIdentifier[];
  disabled?: boolean;
  className?: string;
  isLast?: boolean;
};

type LayerWithComposition = {
  name: string;
  type: AeItemType;
  compositions: string[];
};

export const LayerCombobox = ({
  label,
  description,
  input,
  value,
  onChange,
  compositions,
  disabled,
  className,
  isLast
}: LayerComboboxProps) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const layers = compositions
    ?.reduce((previous, current) => {
      current.layers?.forEach(layer => {
        const index = previous.findIndex(i => i.name === layer?.name);
        // no item
        if (index < 0) {
          previous.push({ name: layer.name, type: layer.type, compositions: [current.name] });
        } else {
          const existing = previous[index].compositions;
          if (existing.findIndex(c => c === current.name) < 0) {
            existing.push(current.name);
          }
        }
      });
      return previous;
    }, [] as LayerWithComposition[])
    .sort((l1, l2) => l1.name.localeCompare(l2.name));

  const warning = layers?.find(l => l.name === input)?.compositions.length === compositions?.length || false;

  const filteredLayers =
    query === ''
      ? layers
      : layers?.filter(l => {
          return l.name.toLowerCase().includes(query.toLowerCase());
        });

  const getLayerCompositions = (layer: LayerWithComposition) => {
    if (layer.compositions.length === 1) {
      return layer.compositions[0];
    } else if (compositions && layer.compositions.length >= compositions.length) {
      return t('components.project.template.layer.scripts.ScriptTargetCombobox.layerCompositions', { context: 'ALL' });
    } else if (compositions && layer.compositions.length < compositions.length) {
      return t('components.project.template.layer.scripts.ScriptTargetCombobox.layerCompositions', {
        context: 'MANY',
        length: layer.compositions.length
      });
    }
  };

  return (
    <Combobox
      id="target"
      as="div"
      value={value ? value : input}
      onChange={onChange}
      className={classNames(className ? className : 'mb-5', disabled && 'cursor-not-allowed opacity-50')}
      disabled={disabled}
    >
      <Label className="block text-sm font-medium text-gray-700">
        <>
          {label}
          <p className="text-sm font-normal text-gray-500">{description}</p>
        </>
      </Label>
      <div className="relative mt-1">
        <ComboboxInput
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={event => setQuery(event.target.value)}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredLayers && filteredLayers.length > 0 && (
          <ComboboxOptions
            className={classNames(
              'absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              isLast ? 'max-h-20' : 'max-h-60'
            )}
          >
            {filteredLayers.map(layer => (
              <ComboboxOption
                key={layer.name}
                value={layer.name}
                className={({ focus }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ focus, selected }) => (
                  <div className="flex">
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{layer.name}</span>
                    <span
                      className={classNames(
                        'ml-5 flex truncate text-gray-500',
                        focus ? 'text-indigo-200' : 'text-gray-500'
                      )}
                    >
                      {getLayerCompositions(layer)}
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
      {warning && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {t('components.project.template.layer.scripts.ScriptTargetCombobox.warning')}
        </p>
      )}
    </Combobox>
  );
};
