import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { updateSentryUser, useActiveOrganizationId, useAxiosModify, useAxiosRead } from '@src/hooks';
import {
  ApiKeyDto,
  CreateApiKeyDto,
  CreateInvitationDto,
  CurrentUsageOverview,
  DetailedResourceUsage,
  ErrorOptions,
  InvoiceDto,
  OrganizationInvitation,
  OrganizationRole,
  PlainlyApiError,
  Subscription,
  SubscriptionIdentifier,
  TeamMemberDto,
  UserDto,
  UserOrganization,
  UserUpdateDto
} from '@src/models';
import { State, useGlobalState } from '@src/state/store';

const ORGANIZATION_CACHE_ROOT = 'organization';

// Cache utils
export const invalidateUsage = (client: QueryClient) => client.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'usage']);

// User
export const useGetUserProfile = (errorOptions?: ErrorOptions) => {
  const { get } = useAxiosRead<UserDto>({}, errorOptions);
  const [, setUser] = useGlobalState(State.USER);
  const [, setOrganization] = useGlobalState(State.ORGANIZATION);
  const { getActiveOrganizationId, removeActiveOrganizationId } = useActiveOrganizationId();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'userProfile'];

  const { data, isLoading, error, refetch } = useQuery<UserDto, PlainlyApiError>(
    cacheKey,
    async () => {
      const response = await get('/user');
      return response.data;
    },
    {
      onSuccess: data => {
        updateSentryUser(data.user);
        setUser(data.user);
        const activeOrganizationId = getActiveOrganizationId();
        // first we check if the user has this active organization
        const organizationExists = data.organizations.some(org => org.id === activeOrganizationId);

        let organization: UserOrganization | undefined;
        if (organizationExists) {
          // if the user has this organization, we use it
          organization = data.organizations.find(org => org.id === activeOrganizationId);
        } else {
          // if the user does not have this organization, we use the default
          organization = data.organizations.find(org => org.id === data.user.defaultOrganizationId);

          // if there is active organization id stored, we remove it
          if (activeOrganizationId) {
            removeActiveOrganizationId();
          }
        }

        setOrganization(organization);
      },
      refetchOnMount: true
    }
  );

  return { data, isLoading, error, refetch };
};

export const useEditUserDetails = () => {
  const { post } = useAxiosModify<UserUpdateDto, UserDto>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (userData: UserUpdateDto) => {
      const response = await post('/user', userData);

      return response.data;
    },
    onSuccess: userProfile => {
      queryClient.setQueryData<UserDto>([ORGANIZATION_CACHE_ROOT, 'userProfile'], userProfile);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useSetDefaultOrganizationId = () => {
  const { post } = useAxiosModify<void, UserDto>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response = await post(`/user/organization/${id}/set-default`);
      return response.data;
    },
    onSuccess: () => queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'userProfile'])
  });

  return { mutateAsync, isLoading };
};

export const useGetUserInvitations = () => {
  const { get } = useAxiosRead<OrganizationInvitation[]>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'userProfile', 'invitations'];

  const { data, isLoading, error } = useQuery<OrganizationInvitation[]>(cacheKey, async () => {
    const response = await get('/user/invitations');
    return response.data;
  });

  return { data, isLoading, error };
};

export const useAcceptUserInvitation = () => {
  const { post } = useAxiosModify<{ makeDefault: boolean }, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ invitationId, makeDefault = false }: { invitationId: string; makeDefault?: boolean }) => {
      const response = await post(`/user/invitations/${invitationId}/accept`, {
        makeDefault
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'userProfile', 'invitations']);
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'invitations']);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useRejectUserInvitation = () => {
  const { post } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (invitationId: string) => {
      const response = await post(`/user/invitations/${invitationId}/reject`);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'userProfile', 'invitations']);
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'invitations']);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useGetApiKeys = () => {
  const { get } = useAxiosRead<ApiKeyDto[]>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'apiKeys'];

  const { data, isLoading, error } = useQuery<ApiKeyDto[]>(cacheKey, async () => {
    const response = await get('/organization/api-keys');
    return response.data;
  });

  return { data, isLoading, error };
};

export const useCreateApiKey = () => {
  const { post } = useAxiosModify<CreateApiKeyDto, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (apiKey: CreateApiKeyDto) => {
      const response = await post('/organization/api-keys', apiKey);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'apiKeys']);
    }
  });

  return { mutateAsync, isLoading, error };
};

// Usage
export const useGetCurrentUsageOverview = () => {
  const { get } = useAxiosRead<CurrentUsageOverview>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'usage', 'current'];

  const { data, isLoading, error } = useQuery<CurrentUsageOverview>(cacheKey, async () => {
    const response = await get('/usage');
    return response.data;
  });

  return { data, isLoading, error };
};

type DetailedUsageParams = {
  resourceType?: string;
  date?: string | null;
};

export const useGetDetailedResourceUsage = (params: DetailedUsageParams) => {
  const { get } = useAxiosRead<DetailedResourceUsage>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'usage', 'details', params];

  const { data, isLoading, error, refetch } = useQuery<DetailedResourceUsage>(cacheKey, async () => {
    const response = await get('/usage/details', { params });
    return response.data;
  });

  return { data, isLoading, error, refetch };
};

// Subscription
export const useGetSubscription = () => {
  const { get } = useAxiosRead<Subscription>();
  const [, setSubscription] = useGlobalState(State.SUBSCRIPTION);

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'subscription'];

  const { data, isLoading, error, refetch } = useQuery<Subscription>(
    cacheKey,
    async () => {
      const response = await get('/subscription');
      return response.data;
    },
    {
      onSuccess: data => {
        setSubscription(data);
      },
      enabled: false
    }
  );

  return { data, isLoading, error, refetch };
};

export const useGetSubscriptionInvoices = () => {
  const { get } = useAxiosRead<InvoiceDto[]>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'subscriptionInvoices'];

  const { data, isLoading, error, refetch } = useQuery<InvoiceDto[]>(cacheKey, async () => {
    const response = await get('/subscription/invoices');
    return response.data;
  });

  return { data, isLoading, error, refetch };
};

export const useUpgradeSubscriptionPlan = () => {
  const { post } = useAxiosModify<{ subscriptionIdentifier: SubscriptionIdentifier }, void>(
    {},
    { silentNotifications: true }
  );
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation<
    boolean,
    PlainlyApiError,
    { subscriptionIdentifier: SubscriptionIdentifier }
  >({
    mutationFn: async (subscription: { subscriptionIdentifier: SubscriptionIdentifier }) => {
      const response = await post('/subscription/upgrade', subscription);
      return response.status === 200;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'subscription']);
    }
  });

  return { mutateAsync, isLoading, error };
};

// team members and invitations

export const useGetTeamMembers = (enabled = true) => {
  const { get } = useAxiosRead<TeamMemberDto[]>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'teamMembers'];

  const { data, isLoading, error } = useQuery<TeamMemberDto[]>(
    cacheKey,
    async () => {
      const response = await get('/organization/team-members');
      return response.data;
    },
    {
      enabled
    }
  );

  return { data, isLoading, error };
};

export const useChangeTeamMemberRole = () => {
  const { post: postMember } = useAxiosModify<{ role: OrganizationRole }, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ userId, role }: { userId: string; role: OrganizationRole }) => {
      const response = await postMember(`/organization/team-members/${userId}`, {
        role
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'teamMembers']);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteTeamMember = () => {
  const { delete: deleteMember } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (userId: string) => {
      const response = await deleteMember(`/organization/team-members/${userId}`);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'teamMembers']);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useGetOrganizationInvitations = () => {
  const { get } = useAxiosRead<OrganizationInvitation[]>();

  const cacheKey = [ORGANIZATION_CACHE_ROOT, 'invitations'];

  const { data, isLoading, error } = useQuery<OrganizationInvitation[]>(cacheKey, async () => {
    const response = await get('/organization/invitations');
    return response.data;
  });

  return { data, isLoading, error };
};

export const useCreateOrganizationInvitation = () => {
  const { post } = useAxiosModify<CreateInvitationDto, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (apiKey: CreateInvitationDto) => {
      const response = await post('/organization/invitations', apiKey);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'invitations']);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteOrganizationInvitation = () => {
  const { delete: deleteInvitation } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (invitationId: string) => {
      const response = await deleteInvitation(`/organization/invitations/${invitationId}`);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_CACHE_ROOT, 'invitations']);
    }
  });

  return { mutateAsync, isLoading, error };
};
