import { useCallback, useEffect, useReducer } from 'react';

import { TemplateDto } from '@src/models';

const storageKey = 'plainly.edit-template';

type State = Record<string, TemplateDto>;

type Action =
  | {
      type: 'update-template';
      payload: {
        templateId: string;
        template: TemplateDto;
      };
    }
  | {
      type: 'remove-template';
      payload: {
        templateId: string;
      };
    };

const initializer = (): State => {
  const storedState = sessionStorage.getItem(storageKey);
  if (storedState) {
    return JSON.parse(storedState) as State;
  } else {
    return {};
  }
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update-template':
      return {
        ...state,
        [action.payload.templateId]: {
          ...(state[action.payload.templateId] || {}),
          ...action.payload.template
        }
      };
    case 'remove-template': {
      const newState = { ...state };
      delete newState[action.payload.templateId];
      return newState;
    }
    default:
      return state;
  }
};

export const useEditTemplateReducer = () => {
  const [state, dispatch] = useReducer(reducer, initializer());

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      sessionStorage.setItem(storageKey, JSON.stringify(state));
    } else {
      sessionStorage.removeItem(storageKey);
    }
  }, [state]);

  const updateTemplate = useCallback(
    (templateId: string, template: TemplateDto) => {
      dispatch({
        type: 'update-template',
        payload: {
          templateId,
          template
        }
      });
    },
    [dispatch]
  );

  const removeTemplate = useCallback(
    (templateId: string) => {
      dispatch({
        type: 'remove-template',
        payload: {
          templateId
        }
      });
    },
    [dispatch]
  );

  return {
    state,
    updateTemplate,
    removeTemplate
  };
};
