import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CheckCircleIcon } from '@heroicons/react/24/solid';
import localizationHelper from '@src/i18n';

import { Button } from '../common';

type SubscriptionPackageProps = {
  name: string;
  price: number;
  packageMinutes: number;
  packageStorage: number;
  packageRetention: number;
  packageHighPro: boolean;
  packageVideoGeniusVideos: number;
  onUpgrade?: () => void;
  selected?: boolean;
  yearly?: boolean;
};

export const SubscriptionPackage = ({
  name,
  price,
  packageMinutes,
  packageStorage,
  packageRetention,
  packageHighPro,
  packageVideoGeniusVideos,
  onUpgrade,
  selected,
  yearly
}: SubscriptionPackageProps) => {
  const { t } = useTranslation();

  const items = [
    t('components.user.SubscriptionPackage.packageVideoMins', { minutes: packageMinutes }),
    t('components.user.SubscriptionPackage.packageStorage', { storage: packageStorage }),
    t('components.user.SubscriptionPackage.packageRetention', { retention: packageRetention }),
    t('components.user.SubscriptionPackage.packagePriorityHigh', { context: `${packageHighPro}` }),
    t('components.user.SubscriptionPackage.packageVideoGeniusVideos', { videos: packageVideoGeniusVideos })
  ];

  return (
    <>
      <div
        className={classNames(
          'rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between',
          selected && 'ring-2 ring-indigo-500 ring-offset-2'
        )}
      >
        <div className="w-full sm:flex sm:items-start">
          <div className="w-full">
            <div className="font-medium text-gray-900">
              {name}
              <span className="ml-2 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
                {t('components.user.SubscriptionPackage.packagePrice', {
                  price: localizationHelper.forNumber().format(price),
                  context: yearly ? 'YEARLY' : 'MONTHLY'
                })}
              </span>
            </div>
            <dl className="mt-1 grid grid-cols-1 gap-x-2 gap-y-0 sm:grid-cols-2">
              {items.map((s, i) => (
                <CheckItem key={i} s={s} />
              ))}
            </dl>
          </div>
        </div>
        <div className="mt-4 sm:ml-6 sm:mt-0 sm:shrink-0">
          <Button onClick={onUpgrade} disabled={selected} secondary={selected}>
            {t('general.action.upgrade')}
          </Button>
        </div>
      </div>
    </>
  );
};

const CheckItem = ({ s }: { s: string }) => (
  <div className="sm:col-span-1">
    <div className="mt-1 flex items-center text-sm text-gray-600">
      {<CheckCircleIcon className="mr-1.5 h-5 w-5 shrink-0 text-green-400" aria-hidden="true" />}
      <div>{s}</div>
    </div>
  </div>
);
