import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  DesignBooleanInput,
  DesignColorInput,
  DesignNumberInput,
  DesignStringInput,
  MediaLayerInput,
  RequiredMarker
} from '@src/components';
import { DesignParameter, DesignParameterType } from '@src/models';
import { ensureColorHex, isEmpty } from '@src/utils';

export type DesignFormFieldProps = {
  hideLabel?: boolean;
  value?: string;
  parameter: DesignParameter;
  onChange: (name: string, value: string) => void;
  onValidation?: (key: string) => (url: string, valid: boolean) => void;
  errors?: string[];
};

export const DesignFormField = ({
  parameter,
  onChange,
  value,
  hideLabel = false,
  onValidation,
  errors
}: DesignFormFieldProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'col-span-6 sm:col-span-3',
        parameter.type === DesignParameterType.BOOLEAN && 'flex',
        !hideLabel && 'mt-3'
      )}
    >
      {parameter.type === DesignParameterType.BOOLEAN && (
        <DesignBooleanInput
          id={`${parameter.name}-field`}
          name={`${parameter.name}-name`}
          aria-describedby={`${parameter.name}-description`}
          onFieldUpdate={value => onChange(parameter.key, value.toString())}
          checked={value === 'true' || parameter.defaultValue}
        />
      )}

      {!hideLabel && (
        <div key={parameter.key} className="mb-1">
          <label htmlFor={`${parameter.key}-field`} className="block text-sm font-medium text-gray-700">
            {parameter.name}
            {!parameter.optional && <RequiredMarker />}
          </label>
          <p id={`${parameter.key}-description`} className="text-sm text-gray-500">
            {parameter.description}
          </p>
        </div>
      )}
      <>
        {parameter.type === DesignParameterType.STRING && (
          <DesignStringInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={value => onChange(parameter.key, value)}
            value={value}
          />
        )}
        {parameter.type === DesignParameterType.COLOR && (
          <DesignColorInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={value => onChange(parameter.key, value)}
            value={ensureColorHex(value)}
          />
        )}
        {parameter.type === DesignParameterType.MEDIA && (
          <MediaLayerInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            onFieldUpdate={(value: string) => onChange(parameter.key, value)}
            value={value}
            onValidation={onValidation?.(parameter.key)}
          />
        )}
        {parameter.type === DesignParameterType.NUMBER && (
          <DesignNumberInput
            id={`${parameter.name}-field`}
            name={`${parameter.name}-name`}
            aria-describedby={`${parameter.name}-description`}
            step="any"
            onFieldUpdate={value => {
              onChange(parameter.key, value.toString());
            }}
            value={value || parameter.defaultValue || 0}
          />
        )}
        {!isEmpty(errors) &&
          errors.map((error, index) => (
            <p key={index} className="mt-1 text-sm text-red-600" id="parameter_name-error">
              {t(error)}
            </p>
          ))}
        <Alert
          className="mt-1"
          show={parameter.type === DesignParameterType.COMPLEX}
          type="info"
          alertContent={t('components.designs.common.complexParameterAlert')}
        />
      </>
    </div>
  );
};
