import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { CircleStackIcon, ExclamationCircleIcon, NewspaperIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import { useGetCurrentUsageOverview, useQueryParams } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ResourceType } from '@src/models';
import * as routes from '@src/routes';
import { toMb } from '@src/utils';

import { StyledLink } from '../common';

const resourceIcon = (type: ResourceType) => {
  switch (type) {
    case ResourceType.STORAGE:
      return <CircleStackIcon className="h-6 w-6 text-white" aria-hidden="true" />;
    case ResourceType.RENDERING_VIDEO_DURATION:
      return <VideoCameraIcon className="h-6 w-6 text-white" aria-hidden="true" />;
    case ResourceType.ARTICLE_VIDEOS:
      return <NewspaperIcon className="h-6 w-6 text-white" aria-hidden="true" />;
  }
};

export const UsageOverview = () => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();
  const { isLoading, data } = useGetCurrentUsageOverview();

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">{t('components.usage.UsageOverview.title')}</h3>
      {isLoading && (
        <>
          <p className="text-sm leading-6 text-gray-600">{t('components.usage.common.loading')}</p>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
            <div className="relative h-32 animate-pulse-tailwind overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" />
            <div className="relative h-32 animate-pulse-tailwind overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6" />
          </dl>
        </>
      )}
      {!isLoading && (
        <>
          <p className="text-sm leading-6 text-gray-600">
            {t('components.usage.UsageOverview.periodInfo', {
              start: localizationHelper.forDate().formatDateStringLocally(data?.startDate),
              endDistance: localizationHelper.forDate().formatDistanceToNowWithDateString(data?.endDate, true)
            })}
          </p>

          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
            {data?.resources.map(item => {
              const { resourceType, current, usagePercentage, available } = item;
              return (
                <div
                  key={resourceType}
                  className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                >
                  <dt>
                    {available && (
                      <div className="absolute rounded-md bg-indigo-500 p-3">{resourceIcon(resourceType)}</div>
                    )}
                    {!available && (
                      <div className="absolute rounded-md bg-red-500 p-3">
                        {<ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      </div>
                    )}
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">
                      {t('components.usage.common.resourceName', { context: resourceType })}
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">
                      {t('components.usage.common.resourceUsage', {
                        context: resourceType,
                        count: Math.round(current),
                        decimal: localizationHelper
                          .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                          .format(current),
                        megabytes: localizationHelper
                          .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                          .format(toMb(current))
                      })}
                    </p>
                    {data.unlimited && (
                      <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                        {t('components.usage.common.unlimited')}
                      </p>
                    )}
                    {!data.unlimited && (
                      <p
                        className={classnames(
                          usagePercentage < 0.9 ? 'text-green-600' : 'text-red-600',
                          'ml-2 flex items-baseline text-sm font-semibold'
                        )}
                      >
                        {t('components.usage.UsageOverview.percentUsed', {
                          percent: Math.round(usagePercentage * 100),
                          context: `${available}`
                        })}
                      </p>
                    )}

                    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                      <div className="text-sm">
                        <StyledLink
                          to={withQueryParams(generatePath(routes.USAGE_DETAILS), {
                            resourceType: resourceType.toLocaleLowerCase()
                          })}
                        >
                          {t('general.action.viewDetails')}
                        </StyledLink>
                      </div>
                    </div>
                  </dd>
                </div>
              );
            })}
          </dl>
        </>
      )}
    </div>
  );
};
