import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { FilmIcon } from '@heroicons/react/24/outline';
import { LanguageIcon } from '@heroicons/react/24/solid';
import { Loading } from '@src/components/common';
import {
  useEditBrand,
  useGetBrandDetails,
  useGetDesignDetails,
  useGetProjectDetails,
  useQueryParams
} from '@src/hooks';
import { AnyVideoSettings, Brand } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

export type VideoTypesListItemProps = PropsWithModel<AnyVideoSettings> & {
  brand: Brand;
  isLast?: boolean;
};

export const VideoTypesListItem = ({ brand, model, isLast }: VideoTypesListItemProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();
  const navigate = useNavigate();

  const { isLoading: isLoadingEditBrand, mutateAsync: editVideoTypes } = useEditBrand();
  const { isLoading: isLoadingBrandDetails, refetch: refetchBrand } = useGetBrandDetails(brand.id, false);

  const isLoading = isLoadingEditBrand || isLoadingBrandDetails;

  const enableDisableAction = async () => {
    const freshBrand = (await refetchBrand()).data;

    if (freshBrand) {
      freshBrand.settings.videoTypes = freshBrand.settings.videoTypes?.map(vt => {
        if (vt.id === model.id) {
          vt.enabled = !vt.enabled;
        }
        return vt;
      });
      await editVideoTypes({ brandId: freshBrand.id, settings: freshBrand.settings });
    }
  };

  const deleteAction = async () => {
    const freshBrand = (await refetchBrand()).data;

    if (freshBrand) {
      freshBrand.settings.videoTypes = freshBrand.settings.videoTypes?.filter(vt => vt.id !== model.id);
      await editVideoTypes({ brandId: freshBrand.id, settings: freshBrand.settings });
    }
  };

  const duplicateAction = async () => {
    const freshBrand = (await refetchBrand()).data;

    if (freshBrand) {
      const newVideoType = { ...model, id: undefined };
      freshBrand.settings.videoTypes.push(newVideoType);
      const response = await editVideoTypes({ brandId: freshBrand.id, settings: freshBrand.settings });
      const duplicatedVideoType = response.settings.videoTypes[response.settings.videoTypes.length - 1];
      navigate(
        withQueryParams(
          generatePath(routes.VIDEO_TYPE_EDIT, {
            id: response.id,
            videoId: duplicatedVideoType.id
          }),
          {
            projectId: duplicatedVideoType.projectId,
            templateId: duplicatedVideoType.templateId
          }
        )
      );
    }
  };

  const menuItems: { label: string; action: () => void }[] = [
    {
      label: model.enabled
        ? t('components.brand.videoTypes.VideoTypesListItem.disable')
        : t('components.brand.videoTypes.VideoTypesListItem.enable'),
      action: enableDisableAction
    },
    {
      label: t('components.brand.videoTypes.VideoTypesListItem.delete'),
      action: deleteAction
    },
    {
      label: t('components.brand.videoTypes.VideoTypesListItem.duplicate'),
      action: duplicateAction
    }
  ];

  return (
    <li
      className={classNames(
        model.enabled && 'hover:bg-gray-50',
        !model.enabled && 'bg-gray-50',
        isLast && 'sm:rounded-b-md'
      )}
    >
      {isLoading && <Loading />}
      {!isLoading && model.id && (
        <Link
          to={withQueryParams(generatePath(routes.VIDEO_TYPE_EDIT, { id: brand.id, videoId: model.id }), {
            projectId: model.projectId,
            templateId: model.templateId
          })}
        >
          <div className="flex items-center gap-x-6 px-4 py-4 sm:px-6">
            <div className="flex flex-1 flex-col justify-between">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <p
                  className={classNames(
                    'flex items-center gap-2 truncate text-sm font-medium text-indigo-600',
                    !model.enabled && 'opacity-50'
                  )}
                >
                  {t('components.brand.BrandsList.videoType', { context: model.videoType })}
                </p>
                <p className={classNames('text-sm leading-6 text-gray-900 sm:hidden', !model.enabled && 'opacity-50')}>
                  {model.enabled ? t('general.common.enabled') : t('general.common.disabled')}
                </p>
                <p
                  className={classNames(
                    'hidden text-sm leading-6 text-gray-900 sm:block',
                    !model.enabled && 'opacity-50'
                  )}
                >
                  {model.enabled ? t('general.common.enabled') : t('general.common.disabled')}
                </p>
              </div>
              {model.design && <DesignItem model={model} />}
              {!model.design && <ProjectItem model={model} />}
            </div>
            <Menu as="div" className="relative flex-none opacity-100">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">{t('general.action.openMenu')}</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {menuItems.map((item, index) => (
                    <MenuItem key={index}>
                      {({ focus }) => (
                        <button
                          onClick={e => {
                            e.preventDefault();
                            item.action();
                          }}
                          className={classNames(
                            focus ? 'bg-gray-50' : '',
                            'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900'
                          )}
                        >
                          {item.label}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </Link>
      )}
    </li>
  );
};

const DesignItem = ({ model }: PropsWithModel<AnyVideoSettings>) => {
  const { t } = useTranslation();
  const { isLoading, data: design } = useGetDesignDetails(model.projectId, true, { silentNotifications: true });

  const template = design?.variants.find(t => t.id === model.templateId);

  return (
    <div
      className={classNames(
        'mt-2 flex w-full flex-col text-sm sm:flex-row sm:items-center sm:justify-between',
        !model.enabled && 'opacity-50'
      )}
    >
      {isLoading && t('components.brand.videoTypes.VideoTypesListItem.loadingDesign')}
      {!isLoading && (
        <>
          {!design && <DoesntExist item="design" />}
          {design && (
            <>
              {!template && <DoesntExist item="template" />}
              {template && (
                <>
                  <p className="flex items-center text-gray-500">
                    <FilmIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                    {`${design.name} / ${template.name}`}
                    <span className="ml-2 rounded-md bg-green-50 px-1.5 py-0.5 text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
                      {t('general.common.design')}
                    </span>
                  </p>
                  <p className="mt-1 flex items-center text-sm text-gray-500 sm:ml-2 sm:mt-0">
                    <LanguageIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                    {model.targetLanguage}
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const ProjectItem = ({ model }: PropsWithModel<AnyVideoSettings>) => {
  const { t } = useTranslation();
  const { isLoading, data: project } = useGetProjectDetails(model.projectId, true, { silentNotifications: true });

  const template = project?.templates.find(t => t.id === model.templateId);

  return (
    <div
      className={classNames(
        'mt-2 flex w-full flex-col text-sm sm:flex-row sm:items-center sm:justify-between',
        !model.enabled && 'opacity-50'
      )}
    >
      {isLoading && t('components.brand.videoTypes.VideoTypesListItem.loadingProject')}
      {!isLoading && (
        <>
          {!project && <DoesntExist item="project" />}
          {project && (
            <>
              {!template && <DoesntExist item="template" />}
              {template && (
                <>
                  <p className="flex items-center text-gray-500">
                    <FilmIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                    {project.name} / {template.name}
                  </p>
                  <p className="mt-1 flex items-center text-sm text-gray-500 sm:ml-2 sm:mt-0">
                    <LanguageIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                    {model.targetLanguage}
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const DoesntExist = ({ item }: { item: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="mt-1 flex items-center text-xs text-yellow-900 md:mt-2">
        <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400" />
        {t('components.brand.videoTypes.VideoTypesListItem.doesntExist', { context: item })}
      </p>
    </div>
  );
};
