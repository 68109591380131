import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticlesListItem, AutoGenerateModal, Button, EmptyState, Loading } from '@src/components';
import { useGetBrands } from '@src/hooks';
import { Brand } from '@src/models';
import * as routes from '@src/routes';

export const ArticlesList = () => {
  const [generateExample, setGenerateExample] = useState(false);
  const { t } = useTranslation();

  const { isLoading, data: brands = [] } = useGetBrands();

  return (
    <>
      {isLoading && <Loading className="flex-1" title={t('components.publishers.common.loading')} />}
      {!isLoading && (
        <>
          {!brands.length && (
            <EmptyState
              title={t('components.article.ArticlesList.empty')}
              subtitle={t('components.publishers.common.emptyGetStarted')}
              route={routes.BRANDS_CREATE}
              buttonText={t('components.publishers.common.newBrand')}
            >
              <div className="mb-6 flex items-center justify-center gap-2">
                <div className="h-px w-24 bg-gray-200" />
                <p className="text-sm text-gray-400">{t('general.common.or')}</p>
                <div className="h-px w-24 bg-gray-200" />
              </div>
              <Button
                disabled={generateExample}
                loading={generateExample}
                className="w-fit"
                secondary
                onClick={() => setGenerateExample(true)}
              >
                {t('components.brand.BrandsList.exampleBrand')}
              </Button>
            </EmptyState>
          )}
          {brands.length > 0 && (
            <div className="space-y-3">
              {brands.map((brand: Brand) => (
                <ArticlesListItem key={brand.id} brand={brand} />
              ))}
            </div>
          )}
        </>
      )}
      <AutoGenerateModal generate={generateExample} setGenerate={setGenerateExample} />
    </>
  );
};
