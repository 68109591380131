import { Fragment, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ArchiveBoxXMarkIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Alert,
  Button,
  EmptyState,
  List,
  Loading,
  PlainlyCombobox,
  ProjectListItem,
  ProjectListItemProps
} from '@src/components';
import { useGetProjects, useQueryParams } from '@src/hooks';
import { AfterEffectsVersion, Project } from '@src/models';
import * as routes from '@src/routes';

enum ProjectAnalysisState {
  done = 'done',
  failed = 'failed',
  pending = 'pending'
}

const sortingOptions: { name: string }[] = [{ name: 'lastModified' }, { name: 'createdDate' }, { name: 'name' }];

export const ProjectList = () => {
  const { t } = useTranslation();
  const { searchQuery, updateQueryParams } = useQueryParams();
  const navigate = useNavigate();

  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const search = searchQuery.get('search');
  const tags = searchQuery.get('tags');
  const version = searchQuery.get('version');
  const state = searchQuery.get('state');
  const sort = searchQuery.get('sort') || 'lastModified';

  const { isLoading, data = [] } = useGetProjects();

  const onTagClick = useCallback(
    (tag: string) => {
      if (tags) {
        if (tags.split(',').includes(tag)) {
          return;
        } else {
          navigate({
            search: `?${updateQueryParams(window.location, {
              tags: `${tags},${tag}`
            })}`
          });
        }
      } else {
        navigate({
          search: `?${updateQueryParams(window.location, {
            tags: tag
          })}`
        });
      }
    },
    [navigate, tags, updateQueryParams]
  );

  const projectAnalysisStates = useMemo(() => Object.keys(ProjectAnalysisState), []);
  const afterEffectsVersions = useMemo(() => Object.keys(AfterEffectsVersion), []);

  const filteredProjects = useMemo(() => {
    const tagsArray = tags ? tags.split(',') : [];

    return data
      .sort((a, b) => {
        if (sort === 'lastModified') {
          return a.lastModified > b.lastModified ? -1 : 1;
        } else if (sort === 'createdDate') {
          return a.createdDate > b.createdDate ? -1 : 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      })
      .filter(p => {
        const projectState = p.analysis.done ? 'done' : p.analysis.failed ? 'failed' : 'pending';
        const matchState = !state || projectState === state;
        const matchTags = !tagsArray || tagsArray.every(tag => p.attributes?.tags?.includes(tag));
        const matchVersion = !version || p.aeVersion === version;
        const matchSearch =
          !search ||
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          p.description?.toLowerCase().includes(search);

        return matchState && matchTags && matchVersion && matchSearch;
      });
  }, [data, search, sort, state, tags, version]);

  const projectAnalysisComboboxData = useMemo(
    () =>
      projectAnalysisStates.map(pas => ({
        name: pas,
        label: t('components.project.common.analysis', { context: pas }),
        item: pas,
        selected: pas === state
      })),
    [projectAnalysisStates, state, t]
  );

  const onSelectionStateChange = useCallback(
    (state: string | undefined) => {
      navigate({ search: `?${updateQueryParams(window.location, { state })}` });
    },
    [navigate, updateQueryParams]
  );

  const aeVersionsComboboxData = useMemo(
    () =>
      afterEffectsVersions.map(aev => ({
        name: aev,
        label: t('components.project.common.aeVersion', { context: aev }),
        item: aev,
        selected: aev === version
      })),
    [afterEffectsVersions, t, version]
  );

  const onSelectionVersionChange = useCallback(
    (version: string | undefined) => {
      navigate({ search: `?${updateQueryParams(window.location, { version })}` });
    },
    [navigate, updateQueryParams]
  );

  return (
    <>
      {isLoading && <Loading className="flex-1" title={t('components.project.ProjectList.loading')} />}
      {!isLoading && (
        <>
          <div className="rounded-xl bg-gray-100 p-0 sm:bg-gray-200 sm:p-2">
            <div className="w-full space-x-0 space-y-2 lg:flex lg:space-x-1 lg:space-y-0">
              {!showSearch && (
                <>
                  <div className="lg:w-1/2">
                    <PlainlyCombobox
                      data={projectAnalysisComboboxData}
                      onSelectionChange={state => onSelectionStateChange(state)}
                      notSelectedLabel={t('components.project.ProjectList.notSelectedAnalysisState')}
                    />
                  </div>
                  <div className="lg:w-1/2">
                    <PlainlyCombobox
                      data={aeVersionsComboboxData}
                      onSelectionChange={version => onSelectionVersionChange(version)}
                      notSelectedLabel={t('components.project.ProjectList.notSelectedAeVersion')}
                    />
                  </div>
                </>
              )}
              <div className={classNames('grid grid-cols-3 lg:flex lg:space-x-1', showSearch && 'w-full gap-y-2')}>
                {showSearch && (
                  <input
                    type="text"
                    name="search-term"
                    id="search-term"
                    className="col-span-3 block w-full flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                    placeholder="Search..."
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' && searchTerm !== '' && searchTerm !== search) {
                        navigate({
                          search: `?${updateQueryParams(window.location, { search: searchTerm })}`
                        });
                      }
                      if (e.key === 'Escape') {
                        setShowSearch(false);
                        navigate({ search: `?${updateQueryParams(window.location, { search: '' })}` });
                      }
                    }}
                    autoFocus
                  />
                )}
                <Button
                  className="col-span-3"
                  secondary={!showSearch}
                  disabled={showSearch && (searchTerm === '' || searchTerm === search)}
                  onClick={() => {
                    if (!showSearch) {
                      setShowSearch(true);
                    } else {
                      navigate({ search: `?${updateQueryParams(window.location, { search: searchTerm })}` });
                    }
                  }}
                  icon={<MagnifyingGlassIcon />}
                >
                  {t('general.action.search')}
                </Button>
                {showSearch && (
                  <Button
                    secondary
                    className="col-span-3 lg:mr-2"
                    onClick={() => {
                      setShowSearch(false);
                      navigate({ search: `?${updateQueryParams(window.location, { search: '' })}` });
                    }}
                  >
                    {t('general.action.cancel')}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {!data.length && (
            <EmptyState
              title={t('components.project.ProjectList.empty')}
              subtitle={t('components.project.ProjectList.emptyGetStarted')}
              route={routes.PROJECTS_CREATE}
              buttonText={t('general.action.upload')}
            >
              <Alert
                type="info"
                show={true}
                alertContent={
                  <Trans
                    i18nKey={'components.project.ProjectList.noProjectsInfo'}
                    tOptions={{
                      phrase: 'this form'
                    }}
                  >
                    Do you need a custom built template? Fill out
                    <a
                      className="text-indigo-600 hover:text-indigo-500"
                      href="https://airtable.com/appwjfPdIwIxZzLn3/shrQziJgM5FopOhiA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this form
                    </a>
                    and we&apos;ll build one for you.
                  </Trans>
                }
              />
            </EmptyState>
          )}
          {data.length && !filteredProjects.length && (
            <EmptyState
              icon={<ArchiveBoxXMarkIcon className="h-12 w-12 text-gray-400" />}
              title={t('components.project.ProjectList.empty', { context: 'filter' })}
            ></EmptyState>
          )}
          {filteredProjects.length > 0 && (
            <List
              data={filteredProjects}
              renderItem={(model: Project) => {
                const props: ProjectListItemProps = { model };
                return <ProjectListItem key={model.id} onTagClick={onTagClick} {...props} />;
              }}
              header={
                <div
                  className={classNames(
                    'flex items-center border-b border-gray-300 px-4 py-4 sm:rounded-t-md sm:px-6',
                    tags ? 'justify-between' : 'justify-end'
                  )}
                >
                  {tags && (
                    <div className="flex flex-wrap gap-2">
                      {tags.split(',').map((tag, index) => (
                        <span
                          key={index}
                          className="inline-flex cursor-pointer items-center whitespace-nowrap rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100"
                          onClick={() => {
                            const newTags = tags.split(',');
                            newTags.splice(index, 1);
                            navigate({
                              search: `?${updateQueryParams(window.location, {
                                tags: newTags.join(',')
                              })}`
                            });
                          }}
                        >
                          {tag}
                          <XMarkIcon className="ml-1 h-3 w-3" />
                        </span>
                      ))}
                    </div>
                  )}
                  <Menu as="div" className="relative">
                    <MenuButton className="flex items-center gap-x-1 text-sm font-medium text-gray-700">
                      <>{t('components.project.ProjectList.sortBy', { context: sort })}</>
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </MenuButton>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {sortingOptions.map((option, index) => (
                          <MenuItem key={index}>
                            <p
                              className={classNames(
                                option.name === sort ? 'font-semibold' : 'font-normal',
                                'relative block cursor-pointer px-8 py-1 text-sm leading-6 text-gray-900'
                              )}
                              onClick={() =>
                                navigate({ search: `?${updateQueryParams(window.location, { sort: option.name })}` })
                              }
                            >
                              <span className={classNames('absolute inset-y-0 left-0 flex items-center pl-1.5')}>
                                {option.name === sort && <CheckIcon className="h-5 w-5" />}
                              </span>
                              {t('components.project.ProjectList.option', { context: option.name })}
                            </p>
                          </MenuItem>
                        ))}
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
};
