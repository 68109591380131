import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ChevronLeftIcon as ArrowLeftIcon, ChevronRightIcon as ArrowRightIcon } from '@heroicons/react/24/outline';
import { MobileMenu, PlainlyLogo, SidebarLinks, UserProfileAvatar } from '@src/components';
import { useAppSettingsReducer } from '@src/hooks';

export const Sidebar = ({
  sidebarFullyCollapsed,
  sidebarCollapsed,
  toggleSideBar
}: {
  sidebarFullyCollapsed: boolean;
  sidebarCollapsed: boolean;
  toggleSideBar: () => void;
}) => {
  const { settings, toggleSidebar } = useAppSettingsReducer();
  const { sidebarDesktopCollapsed, sidebarDesktopFullyCollapsed } = settings;

  const toggleSideBarDesktop = () => {
    if (sidebarDesktopCollapsed) {
      setTimeout(() => {
        toggleSidebar(!sidebarDesktopCollapsed, !sidebarDesktopFullyCollapsed);
      }, 300);
    } else {
      toggleSidebar(!sidebarDesktopCollapsed, !sidebarDesktopFullyCollapsed);
    }
    toggleSidebar(!sidebarDesktopCollapsed, true);
  };

  return (
    <>
      <MobileMenu
        sidebarFullyCollapsed={sidebarFullyCollapsed}
        sidebarCollapsed={sidebarCollapsed}
        toggleSideBar={toggleSideBar}
      />
      <div className="hidden md:flex md:shrink-0">
        <div
          className={classNames(
            'flex flex-col transition-all duration-300 ease-in-out',
            !sidebarDesktopCollapsed ? 'w-64' : 'w-14'
          )}
        >
          <div className="flex h-0 flex-1 flex-col bg-gray-800 py-5">
            <UserProfileAvatar sidebarDesktopFullyCollapsed={sidebarDesktopFullyCollapsed} />
            <SidebarLinks sidebarDesktopFullyCollapsed={sidebarDesktopFullyCollapsed} />
            <div
              className={classNames(
                'flex shrink-0 items-center justify-between',
                sidebarDesktopFullyCollapsed ? 'px-2' : 'px-4'
              )}
            >
              {!sidebarDesktopFullyCollapsed && (
                <Link to="/" title={`v ${import.meta.env.VITE_APP_VERSION}`}>
                  <PlainlyLogo className="h-12 w-auto" />
                </Link>
              )}
              <button
                type="button"
                className={classNames(
                  'hidden cursor-pointer rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-gray-300 md:flex',
                  sidebarDesktopFullyCollapsed && 'my-1'
                )}
                onClick={e => {
                  (e.target as HTMLButtonElement).disabled = true;
                  toggleSideBarDesktop?.();
                  setTimeout(() => {
                    (e.target as HTMLButtonElement).disabled = false;
                  }, 300);
                }}
              >
                {!sidebarDesktopFullyCollapsed && <ArrowLeftIcon className="pointer-events-none h-6 w-6" />}
                {sidebarDesktopFullyCollapsed && <ArrowRightIcon className="pointer-events-none h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
