import { ExtraOptions, OutputFormat, OutputFormatDto, PostEncode, RenderParameters, Webhook } from '@src/models';
import { makeCurl, undefinedIfNull } from '@src/utils';

import { useGetApiKeys } from '../api/useOrganizationApi';

type DesignCurlProps = {
  designId: string;
  variantId: string;
  colorPalette?: string;
  parameters?: RenderParameters;
  inputs?: Record<string, unknown>;
  outputFormat?: OutputFormat | OutputFormatDto;
  webhook?: Webhook;
  options?: ExtraOptions;
  url?: string;
};

export const useDesignCurl = ({
  designId,
  variantId,
  colorPalette,
  parameters,
  inputs,
  outputFormat,
  webhook,
  options,
  url
}: DesignCurlProps) => {
  const { data: apiKeys = [] } = useGetApiKeys();
  const apiKey = apiKeys?.find(k => !k.expired)?.key;
  const finalUrl = url || `${import.meta.env.VITE_APP_BASE_URL}/api/v2/designs`;

  let body: DesignCurlProps = {
    designId,
    variantId
  };

  if (colorPalette) {
    body = { ...body, colorPalette };
  }

  if (inputs) {
    body = { ...body, inputs };
  }

  if (parameters) {
    if (colorPalette) {
      Object.keys(parameters)
        .filter(key => key.includes('color'))
        .forEach(key => delete parameters[key]);
    }

    body = { ...body, parameters };
  }

  if (outputFormat) {
    const { format, encodingParams, settingsTemplate, postEncoding } = outputFormat;
    if (format || encodingParams || settingsTemplate || postEncoding) {
      let basePostEncoding: PostEncode | undefined;

      if (postEncoding?.type === 'captions') {
        basePostEncoding = postEncoding.baseEncoding;
      } else {
        basePostEncoding = postEncoding;
      }

      body = {
        ...body,
        outputFormat: {
          format: undefinedIfNull(format),
          encodingParams: undefinedIfNull(encodingParams),
          settingsTemplate: undefinedIfNull(settingsTemplate),
          postEncoding: undefinedIfNull(basePostEncoding)
        }
      };
    }
  }

  if (webhook) {
    body = { ...body, webhook };
  }

  if (options) {
    body = { ...body, options };
  }

  const jsonBody = JSON.stringify(body, null, 2);
  const curlWithApiKey = makeCurl(jsonBody, finalUrl, apiKey);
  const curlWithoutApiKey = makeCurl(jsonBody, finalUrl);

  return { curlWithApiKey, curlWithoutApiKey };
};
