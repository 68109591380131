export enum ResourceType {
  STORAGE = 'STORAGE',
  RENDERING_VIDEO_DURATION = 'RENDERING_VIDEO_DURATION',
  ARTICLE_VIDEOS = 'ARTICLE_VIDEOS'
}

export enum ResourceUsageChangeType {
  STORAGE_PROJECT_UPLOAD = 'STORAGE_PROJECT_UPLOAD',
  STORAGE_PROJECT_RE_UPLOAD = 'STORAGE_PROJECT_RE_UPLOAD',
  STORAGE_PROJECT_DELETE = 'STORAGE_PROJECT_DELETE',
  RENDERING_RENDER = 'RENDERING_RENDER',
  RENDERING_FAILURE = 'RENDERING_FAILURE',
  RENDERING_CANCELLED = 'RENDERING_CANCELLED',
  RENDERING_DURATION_DIFFERENCE = 'RENDERING_DURATION_DIFFERENCE',
  ARTICLE_VIDEOS_ADD = 'ARTICLE_VIDEOS_ADD'
}

export type BillingPeriod = {
  startDate: string;
  endDate: string;
};

export type DetailedResourceUsage = {
  resourceType: ResourceType;
  startDate: string;
  endDate: string;
  periodTotal: number;
  grandTotal: number;
  max: number;
  usagePercentage: number;
  dailyUsages: DailyUsage[];
  previousBillingPeriods: BillingPeriod[];
};

export type DailyUsage = {
  changeType: ResourceUsageChangeType;
  date: string;
  total: number;
  count: number;
};

export type CurrentUsageOverview = {
  unlimited: boolean;
  startDate: string;
  endDate: string;
  resources: {
    resourceType: ResourceType;
    current: number;
    max: number;
    usagePercentage: number;
    available: boolean;
    resetAtEnd: boolean;
  }[];
};
