import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { Button } from '@src/components/common';
import { Brand } from '@src/models';
import * as routes from '@src/routes';

export const ArticlesListItemHeader = ({ brand }: { brand: Brand }) => {
  const { t } = useTranslation();
  const hasEnabledVideoTypes = brand.settings.videoTypes.filter(vt => vt.enabled).length > 0;

  return (
    <div className="border-b border-gray-300 pb-5">
      <div className="flex flex-col items-start gap-4 md:flex-row md:items-center md:justify-between md:gap-0">
        <Link
          to={generatePath(routes.ARTICLES_BRAND_LIST, { brandId: brand.id })}
          title={t('components.article.ArticlesList.seeAllArticles')}
        >
          <div className="flex min-w-0 flex-1 items-center gap-4">
            <img
              className="h-12 w-12 flex-none rounded-full bg-gray-50 object-contain"
              src={brand.settings.logoUrl}
              alt={brand.settings.name}
            />
            <h2 className="text-lg font-medium leading-6 text-gray-900">{brand.settings.name}</h2>
          </div>
        </Link>
        <div className="flex gap-2">
          <Link to={generatePath(routes.BRAND_DETAILS, { id: brand.id })}>
            <Button secondary>{t('components.article.ArticlesList.brandDetails')}</Button>
          </Link>
          <Link to={generatePath(routes.ARTICLE_CREATE, { brandId: brand.id })}>
            <Button
              disabled={!hasEnabledVideoTypes}
              title={!hasEnabledVideoTypes ? t('components.article.ArticleCreateForm.missingVideoTypes') : undefined}
            >
              {t('components.publishers.common.createArticle')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
