import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { PlusIcon } from '@heroicons/react/16/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { BatchRenderPassthrough } from '@src/types';

import { Checkbox, StyledA } from '../common';

type RenderBatchPassthroughTableProps = {
  title: string;
  passthrough: BatchRenderPassthrough;
  setPassthrough: (passthrough: BatchRenderPassthrough) => void;
  data: Record<string, string>[];
  onShowModal: () => void;
  id: string;
};

export const RenderBatchPassthroughTable = ({
  title,
  passthrough,
  setPassthrough,
  data,
  onShowModal,
  id
}: RenderBatchPassthroughTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 py-2">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="w-2/3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              {title}
            </th>
            <th
              scope="col"
              className="hidden w-1/3 px-3 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell"
            >
              <div className="flex w-full justify-end">
                <button
                  type="button"
                  className="group -ml-1 flex items-center rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={onShowModal}
                >
                  <span className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                    <PlusIcon className="h-4 w-4" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                    {t('components.render.RenderBatchTable.webhookPassthroughButton')}
                  </span>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        {passthrough && Object.keys(passthrough.passthrough).length > 0 && (
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td colSpan={2}>
                <div className="grid grid-cols-3 bg-white pt-4">
                  {Object.keys(passthrough.passthrough).map(pass => (
                    <div
                      key={pass}
                      className="mx-2 mb-4 cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none "
                    >
                      <div className="flex h-full">
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <span className="block text-sm font-medium text-gray-900">
                              {passthrough.passthrough[pass].isColumnSelect
                                ? t('components.render.RenderBatchTable.webhookPassthroughColumn', {
                                    column: passthrough.passthrough[pass].value
                                  })
                                : t('components.render.RenderBatchTable.webhookPassthroughCustomValue')}
                            </span>
                            <span className="mt-1 flex flex-1 items-center text-sm text-gray-500">
                              {passthrough.passthrough[pass].isColumnSelect
                                ? t('components.render.RenderBatchTable.webhookPassthroughExamples', {
                                    examples: passthrough.passthrough[pass].isColumnSelect
                                      ? data
                                          .flatMap(d => d[passthrough.passthrough[pass].value])
                                          .slice(0, 3)
                                          .join(', ')
                                          .concat('...')
                                      : passthrough.passthrough[pass].value
                                  })
                                : t('components.render.RenderBatchTable.webhookPassthroughValue', {
                                    value: passthrough.passthrough[pass].value
                                  })}
                            </span>
                            <span className="mt-6 text-sm text-gray-500">
                              <Trans
                                i18nKey={'components.render.RenderBatchTable.webhookPassthroughParameterName'}
                                tOptions={{ name: pass }}
                                context={passthrough.sendAsJson ? 'json' : 'plain'}
                              >
                                {passthrough.sendAsJson ? 'As JSON parameter' : 'As plain value'}
                                <code className="prose prose-sm text-sm font-medium text-gray-900">{pass}</code>.
                              </Trans>
                            </span>
                          </span>
                        </span>
                        <div>
                          <StyledA>
                            <XMarkIcon
                              className={classNames('h-5 w-5')}
                              aria-hidden="true"
                              onClick={() => {
                                const newWebhookPass = { ...passthrough.passthrough };
                                delete newWebhookPass[pass];
                                setPassthrough({ ...passthrough, passthrough: newWebhookPass });
                              }}
                            />
                          </StyledA>
                        </div>
                        <span className="pointer-events-none absolute -inset-px rounded-lg" aria-hidden="true" />
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  id={id}
                  checked={passthrough.sendAsJson}
                  onChange={e => setPassthrough({ ...passthrough, sendAsJson: e.target.checked })}
                  label={t('components.render.RenderBatchPassthroughTable.sendAsJson')}
                  description={t('components.render.RenderBatchPassthroughTable.sendAsJsonDescription')}
                  disabled={Object.keys(passthrough.passthrough).length > 1}
                  className={'pb-5 pl-3 pt-4'}
                />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};
