import { AeItemType } from '@plainly/types';
import { AnyAeItem, CompositionAeItem, CompositionIdentifier, ProjectMetaData } from '@src/models';

import { AeItemWithCompositions } from '../TemplateLayersForm';

export const getRootComposition = (meta: ProjectMetaData, nameOrId?: string | number) => {
  if (nameOrId) {
    for (const comp of meta) {
      const found =
        typeof nameOrId === 'string' ? matchCompositionName(comp, nameOrId) : matchCompositionId(comp, nameOrId);
      if (found) {
        return found;
      }
    }
  }
};

export const matchCompositionName = (comp: CompositionAeItem, name?: string): CompositionAeItem | undefined => {
  if (comp.name === name) {
    return comp;
  } else {
    for (const child of comp.children) {
      if (child.type === AeItemType.COMPOSITION) {
        const found = matchCompositionName(child as CompositionAeItem, name);
        if (found) {
          return found;
        }
      }
    }
  }
  return undefined;
};

export const matchCompositionId = (comp: CompositionAeItem, id?: number): CompositionAeItem | undefined => {
  if (comp.id === id) {
    return comp;
  } else {
    for (const child of comp.children) {
      if (child.type === AeItemType.COMPOSITION) {
        const found = matchCompositionId(child as CompositionAeItem, id);
        if (found) {
          return found;
        }
      }
    }
  }
  return undefined;
};

export const collectLayers = (
  composition: CompositionAeItem,
  parentCompositions: CompositionIdentifier[] = [],
  result: AeItemWithCompositions[] = []
) => {
  const thisComp: CompositionIdentifier = {
    name: composition.name,
    id: composition.id,
    layers: composition.children.map(c => {
      return { name: c.name, type: c.type };
    })
  };
  // Add root comp
  if (parentCompositions.length === 0) {
    result.push({
      layer: composition as CompositionAeItem,
      compositions: []
    });
  }

  const compositions: CompositionIdentifier[] = [...parentCompositions, thisComp];
  composition.children
    .sort((c1, c2) => c1.name.localeCompare(c2.name))
    .forEach(element => {
      const existing = result.find(i =>
        i.layer.internalId
          ? i.layer.internalId === element.internalId
          : i.layer.name === element.name && i.layer.value === element.value
      );
      if (existing) {
        // only push if none matches
        const index = existing.compositions.findIndex(c => {
          if (c.length !== compositions.length) {
            return false;
          }

          for (let i = 0; i < c.length; i++) {
            if (c[i].id !== compositions[i].id) {
              return false;
            }
          }

          return true;
        });

        if (index < 0) {
          existing.compositions.push(compositions);
        }
      } else {
        result.push({
          layer: element as AnyAeItem,
          compositions: [compositions]
        });
      }

      if (element.type === AeItemType.COMPOSITION) {
        collectLayers(element as CompositionAeItem, compositions, result);
      }
    });

  return result;
};
