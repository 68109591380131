import { useTranslation } from 'react-i18next';

import { AeItemType, AeMediaItemType } from '@plainly/types';
import { AeItemWithCompositions, LayerAvailableListItem } from '@src/components';
import { AeItem, Layer, MediaAeItem } from '@src/models';

type LayerAvailableListProps = {
  projectId: string;
  items: AeItemWithCompositions[];
  onItemClick: (item: AeItemWithCompositions) => void;
  parametrizedLayers: Partial<Layer>[];
};

export const LayerAvailableList = ({ projectId, items, onItemClick, parametrizedLayers }: LayerAvailableListProps) => {
  const { t } = useTranslation();
  const textItems = items.filter(i => i.layer.type === AeItemType.TEXT);
  const mediaItems = items.filter(i => i.layer.type === AeItemType.MEDIA);
  const compositionItems = items.filter(i => i.layer.type === AeItemType.COMPOSITION);

  const alreadyParametrized = (layer: AeItem<AeItemType>) =>
    parametrizedLayers.some(l => l.internalId === layer.internalId);

  return (
    <nav className="relative overflow-y-auto" aria-label="Directory">
      {textItems.length > 0 && (
        <div key={`avbl-type-text`}>
          <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-4 py-1 text-sm font-medium text-gray-500 sm:px-6">
            <h3>{t('components.project.template.common.layerType', { context: `text` })}</h3>
          </div>
          <ul className="relative z-0 divide-y divide-gray-200">
            {textItems.map((item, index) => (
              <LayerAvailableListItem
                key={`avbl-item-text-${index}`}
                projectId={projectId}
                layer={item.layer}
                compositions={item.compositions}
                onClick={() => onItemClick(item)}
                parametrized={alreadyParametrized(item.layer)}
              />
            ))}
          </ul>
        </div>
      )}
      {Object.values(AeMediaItemType).map(type => {
        const layersOfType = mediaItems.filter(item => (item.layer as MediaAeItem).mediaType === type);

        if (layersOfType.length === 0) {
          return null;
        }

        return (
          <div key={`avbl-type-${type.toLocaleLowerCase()}`}>
            <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-4 py-1 text-sm font-medium text-gray-500 sm:px-6">
              <h3>{t('components.project.template.common.layerType', { context: `${type.toLocaleLowerCase()}` })}</h3>
            </div>
            <ul className="relative divide-y divide-gray-200">
              {layersOfType.map((item, index) => {
                let isUnknownLayerType = false;
                if (item.layer.type === AeItemType.MEDIA) {
                  isUnknownLayerType = item.layer.mediaType === AeMediaItemType.UNKNOWN;
                }

                return (
                  <LayerAvailableListItem
                    key={`avbl-item-${type}-${index}`}
                    projectId={projectId}
                    layer={item.layer}
                    compositions={item.compositions}
                    onClick={() => onItemClick(item)}
                    parametrized={alreadyParametrized(item.layer)}
                    disabled={isUnknownLayerType}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}
      {compositionItems.length > 0 && (
        <div key={`avbl-type-comp`}>
          <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-4 py-1 text-sm font-medium text-gray-500 sm:px-6">
            <h3>{t('components.project.template.common.layerType', { context: `composition` })}</h3>
          </div>
          <ul className="relative z-0 divide-y divide-gray-200">
            {compositionItems.map((item, index) => (
              <LayerAvailableListItem
                key={`avbl-item-comp-${index}`}
                projectId={projectId}
                layer={item.layer}
                compositions={item.compositions}
                onClick={() => onItemClick(item)}
              />
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};
