import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { SignalIcon } from '@heroicons/react/24/solid';
import { Render, RenderState } from '@src/models';

export const VideoOutput = ({ render }: { render: Render }) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '200px 0px'
  });

  const inProgress = (
    <div className="relative flex h-[230px] w-full flex-col justify-center rounded-lg border-2 border-dashed border-gray-300 p-12 text-center text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <SignalIcon className="mx-auto h-8 w-8 animate-spin" />
      <span className="mt-2 block text-sm text-gray-500">{t('components.common.pendingRender')}</span>
    </div>
  );

  return (
    <div
      style={{ outlineOffset: '-2px' }}
      className="aspect-h-7 aspect-w-10 flex h-[230px] w-full items-center justify-center overflow-hidden rounded-lg outline-dashed outline-2 outline-offset-0 outline-gray-300 hover:opacity-75"
    >
      {render.state === RenderState.DONE && render.output && !render.expired ? (
        <video
          ref={ref}
          poster={render.thumbnailUris ? render.thumbnailUris[0] : undefined}
          controls
          src={render.output}
          className="z-10 max-h-[230px] w-full"
          preload={inView ? 'auto' : 'none'}
        />
      ) : [RenderState.PENDING, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(render.state) ? (
        inProgress
      ) : (
        <div className="flex w-full justify-center bg-white">
          <img
            className="h-[230px] w-auto object-cover"
            src="/assets/no-preview-available.png"
            alt="Preview not available."
          />
        </div>
      )}
    </div>
  );
};
