import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DeleteAction } from '@src/components';
import { useDeleteProject } from '@src/hooks';
import * as routes from '@src/routes';

export const ProjectDeleteAction = ({
  projectId,
  projectName,
  disabled
}: {
  projectId: string;
  projectName: string;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, mutateAsync: deleteProject } = useDeleteProject();

  const doDelete = () => {
    deleteProject(projectId).then(() => navigate(generatePath(routes.PROJECTS_LIST)));
  };

  return (
    <DeleteAction
      loading={isLoading}
      title={t('components.project.ProjectDeleteAction.title')}
      text={t('components.project.ProjectDeleteAction.text')}
      actionLabel={t('components.project.ProjectDeleteAction.action')}
      confirmMatch={projectName}
      onConfirmedDelete={doDelete}
      disabled={disabled}
    />
  );
};
