import { useState } from 'react';
import { QueryClient } from 'react-query';

import { useError, useNotifications } from '@src/hooks';
import { ErrorCode, PlainlyApiError } from '@src/models';
import { USER_SETTINGS_GENERAL } from '@src/routes';
import { reportExceptionToSentry } from '@src/tools/sentry';

export const useReactQueryClient = () => {
  const { notifyError } = useNotifications();
  const { formatMessage } = useError();

  const onError = (error: unknown) => {
    if (error instanceof PlainlyApiError) {
      const { silentNotifications, sentryErrorFilter, throwOnError } = error.options;

      // push to notification ui
      if (!silentNotifications) {
        const message = formatMessage(error);
        message && notifyError(message, undefined, error.errors);
      }

      // push to sentry if needed
      if (!sentryErrorFilter || sentryErrorFilter(error)) {
        reportExceptionToSentry(error);
      }

      if (error.code === ErrorCode.USER_ORGANIZATION_DOES_NOT_EXIST) {
        window.location.href = USER_SETTINGS_GENERAL + '?showOrganizationMissing=true';
      } else {
        if (throwOnError) {
          throw error;
        }
      }
    } else {
      // report other errors to sentry
      reportExceptionToSentry(error);
    }
  };

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          onError
        },
        mutations: {
          retry: false,
          onError
        }
      }
    });
  });

  return queryClient;
};
