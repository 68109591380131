import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dashboard } from '@src/components';
import { useError, useGetSubscription, useGetUserProfile } from '@src/hooks';
import { ErrorCode } from '@src/models';
import { ErrorPage, LoadingPage, UserNotVerifiedPage, UserPendingInvitePage } from '@src/pages';

export const DashboardWithUser: React.FunctionComponent = props => {
  const {
    isLoading: loadingUser,
    data: user,
    error: errorUser,
    refetch: refetchUser
  } = useGetUserProfile({ silentNotifications: true });
  const navigate = useNavigate();

  const { isApiErrorCode } = useError();

  const userNotVerified = errorUser && isApiErrorCode(errorUser, ErrorCode.USER_EMAIL_NOT_VERIFIED);
  const userHasPendingInvite = errorUser && isApiErrorCode(errorUser, ErrorCode.USER_ORGANIZATION_INVITE_PENDING);

  const { isLoading: loadingSubscription, data: subscription, refetch: getSubscription } = useGetSubscription();

  const resolveInvite = useCallback(
    (accepted: boolean) => {
      // always refetch user after resolving invite
      refetchUser()
        // if he did not accept the invite, and we have the user, navigate to onboarding
        .then(updatedUser => {
          if (!accepted && updatedUser) {
            navigate('/onboarding');
          }
        });
    },
    [refetchUser, navigate]
  );

  // ensure subscription is loaded once we have a user
  // new users will be created on the get user call
  useEffect(() => {
    if (user && !subscription && !loadingSubscription) {
      getSubscription();
    }
  }, [getSubscription, loadingSubscription, subscription, user]);

  return (
    <>
      {loadingUser && <LoadingPage />}
      {!loadingUser && (
        <>
          {errorUser && (
            <>
              {userNotVerified && <UserNotVerifiedPage />}
              {userHasPendingInvite && <UserPendingInvitePage onResolve={resolveInvite} />}
              {!userNotVerified && !userHasPendingInvite && <ErrorPage error={errorUser} />}
            </>
          )}
          {!errorUser && user && <Dashboard {...props} />}
        </>
      )}
    </>
  );
};
